.loadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    p {
      margin-top: 8px;
    }
  }
  