@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.wrapper {
  width: 100%;
  background: url('../../images/bg/404.svg') repeat-x;
  @media (max-width: $sm768) {
    background: url('../../images/bg/404.svg') no-repeat 34% 8%;
  }
  overflow: hidden;
  .content{
    text-align: center;
    margin-top: 80px;
    overflow: hidden;
    .title {
      font-size: 56px;
      color: $gold;
      line-height: 150%;
      @media (max-width: $sm768) {
        font-size: 36px;
      }
      @media (min-width: $sm768) {
        font-size: 56px;
      }
    }
    .subTitle {
      margin-top: 24px;
      font-size: 28px;
      color: $white1;
      line-height: 40px;
      @media (max-width: $sm768) {
        font-size: 16px;;
      }
      @media (min-width: $sm768) {
        font-size: 28px;
      }
    }
  }
  .image {
    max-width: 600px;
    margin: -82px auto 0;
    @media (max-width: $sm768) {
      max-width: 200px;
      margin-top: 32px;
    }
    @media (min-width: $sm768) {
      max-width: 400px;
      margin-top: 32px;
    }
  }
  .bridgeImage{
    width: 100%;
    margin-top: -95px;
    display: inline-block;
    @media (max-width: $sm768) {
      margin-top: 0;
      transform: scale(1.9);
    }
    @media (min-width: $sm768) {
      margin-top: 0;
      transform: scale(1.9);
    }
    @media (min-width: $md1440) {
      margin-top: 0;
      transform: scale(1.4);
    }
    @media (min-width: $lg1920) {
      margin-top: -10px;
      transform: scale(1);
    }
    @media (min-width: $lg1920+1) {
      margin-top: -121px;
    }

  }
}
