@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.departmentsContainer {
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: $md) {
    width: 720px;
  }

  @media (max-width: $sm) {
    width: 100%;
  }

  .breadcrumbs {
    margin: 16px 0;
    color: rgba($white1, 0.7);

    @media (max-width: $sm) {
      margin: 16px;
    }

    .link {
      color: rgba($white1, 0.7);
      text-decoration: underline !important;
      &:hover {
        text-decoration: none !important;
      }
    }
    .currentLink {
      cursor: initial;
      text-decoration: none !important;
    }
  }

  .departmentsBox {
    background: $black4;
    min-height: 606px;
    display: flex;
    flex-direction: column;

    .topInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      padding: 14px 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.32);
    }

    .emptyPage {
      height: 100%;
      font-weight: 300;
      color: white;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
