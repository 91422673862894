@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.prospectsCardWrap {
  width: 100%;
  height: 144px;
  background: #212121;
  margin-bottom: 24px;
  padding: 8px 16px;

  @media (max-width: $sm768) {
    height: auto;
    padding-bottom: 16px;
  }

  .job {
    color: #fa3f48;
  }

  .mentoring {
    color: #ff9800;
  }

  .project {
    color: #7986cb;
  }

  .sponsorship {
    color: #004d40;
  }

  .advisoryboard {
    color: #1565c0;
  }

  .boardofdirectors {
    color: #7e57c2;
  }

  .funding {
    color: #4db6ac;
  }

  .supplier {
    color: #bf5361;
  }

  .userBar {
    font-weight: bold;
    display: block;
    min-width: 64px;
    min-height: 64px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-right: 18px;

    &Name {
      word-break: break-all;
      color: $white1;
      font-weight: bold;
      padding-right: 5px;

      @media (max-width: $xs425) {
        word-break: normal;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
      }
    }
  }

  .duration {
    font-weight: lighter;
  }

  .cardImg {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .vectorWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;

    .vector {
      padding-top: 8px;
      height: 20px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      width: 10%;
    }
  }

  .heartWrap {
    display: flex;
    justify-content: space-between;

    .heart {
      color: $text2;
      cursor: pointer;
      display: flex;
      align-items: center;

      .filledHeart {
        fill: $gold;
      }
    }

    .dateOf {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      margin-bottom: 4px;
      text-align: left;
    }

    .company {
      cursor: pointer;
      color: white;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .userPageLink {
    text-decoration: none !important;
    width: 90%;

    :active,
    :hover,
    :focus {
      text-decoration: none !important;
    }
  }

  .opportunityInfoItem {
    @media (max-width: $sm768) {
      display: block;
      max-width: 152px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (min-width: $sm768) {
      display: inline-block;
      max-width: unset;
      overflow: unset;
      text-overflow: unset;
    }

    .dot {
      @media (max-width: $sm768) {
        display: none;
      }

      @media (min-width: $sm768) {
        display: unset;
        margin: 0 5px;
      }
    }
  }
}
