@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.infoSection {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  overflow: hidden;
  .OpportunityParamsItemStyles{
    background-color: red;
  }
}
.label {
  width: 100px;
  text-align: center;
  position: absolute;
  right: -26px;
  top: 18px;
  transform: rotate(45deg);
  @media (max-width: $md) {
    display: none;
  }
}



