@import "../../UIKit/colorVars";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  svg {
    transition: all 0.1s;
  }

  &[disabled] {
    opacity: 0.38;
    pointer-events: none;

    .button,
    .dropdown {
      color: white;
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
}

.label {
  color: $text2 !important;
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.labelWhite {
  color: rgba(0, 0, 0, 0.87) !important;
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
.dropdown {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.7);

  border-radius: 2px;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  min-width: 100px;
  height: 48px;
  margin-top: 1px;
  color: $black2;
  padding-left: 10px;
  .placeholder {
    color: $gray9;
  }
  &WithBorder {
    border-color: rgba(255, 255, 255, 0.7);
  }
  &:focus {
    outline: none;
  }

  &:hover {
    background: #616161;
  }

  &Error {
    border: 1px solid #ff0c3e !important;
    height: 50px;
    margin-top: 0;
    &:focus,
    &:hover {
      border-color: #ff0c3e !important;
    }
  }
}

.options {
  margin-top: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  width: 100%;
  max-height: 216px;
  overflow: hidden;
  z-index: 5;
  opacity: 0;
  transition: all 0.1s;
  border-radius: 2px;
  &Visible {
    opacity: 1;
    visibility: visible;
  }

  &Wrapper {
    position: absolute;
    padding: 4px 0 4px 0;
    top: 0;
    left: 0;
    right: -17px;
    bottom: 0;
    overflow: hidden;
    min-height: 200px;
    overflow-y: auto;
    width: 100%;
  }
}

.option {
  color: $black1;
  font-size: 16px;
  text-transform: capitalize;
  padding: 0.5em;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: $white3;
  }

  &Selected {
    background-color: $white3;
  }

  &Disabled {
    background-color: $gray7 !important;
    cursor: not-allowed;
  }

  &Default {
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-align: left;
    padding: 13px 8px;
    overflow: hidden;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
  }
}

.valueSelected {
  background: #424242;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding-left: 8px;
  outline: none;
  height: 48px;
  color: white;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 1px;
}
.isWhiteBackground {
  background: #ffffff;
  border: 1px solid #d5d7db;
  border-radius: 2px;

  div {
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    line-height: 16px !important;
  }
  path {
    fill: rgba(0, 0, 0, 0.38) !important;
  }
  &:hover {
    background: #ffffff;
  }
}
.selectedWhite {
  line-height: 16px !important;
  margin-top: 3px;
  align-items: center;
  text-transform: capitalize !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  font-weight: normal !important;
}
.placholderWhite {
  color: rgba(0, 0, 0, 0.38) !important;
  line-height: 16px !important;
  display: flex;
  align-items: center;
}
.dropdownWithBorder {
  border-color: rgba(255, 255, 255, 0.7);
}
.darkDrodown {
  background: #212121;
}
.grayDropdown {
  background-color: #424242;
}
.button {
  width: 40px;
  height: 46px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    path {
      fill: #ffffff;
    }
  }
}

.error {
  color: $red1;

  &Text {
    white-space: nowrap;
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
    color: $red1;
    position: absolute;
    display: block;
    left: 0;
    top: 100%;
  }
}

.errorMessageWrap {
  display: flex;
  margin-top: 4px;
  top: 100%;

  svg {
    min-width: 14px;
  }
}

.blackErrorMessage {
  p.errorMessage {
    color: black;
  }
}
p.errorMessage {
  color: $white1;
  font-size: 11px;
  line-height: 16px;
  margin-left: 4px;
  text-align: left;
}

.withoutError {
  max-height: 74px;
}
