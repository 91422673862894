@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.wrapper {
  width: 282px;
  height: 152px;
  padding: 16px;
  background-color: $black4;
  display: inline-block;
  text-align: left;
  margin-bottom: 24px;
  cursor: pointer;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2),
  0 3px 16px rgba(0, 0, 0, 0.12),
  0 9px 12px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    width: 100%
  }
  @media (min-width: $sm768) {
    width: calc(50% - 12px);
  }

  @media (min-width: 1024px) {
    width: 282px;
  }
  .title {
    cursor: pointer;
    color: $white1;
    font-style: normal;
    font-weight: 500;
  }
  .text{
    cursor: pointer;
    p {
      display: none;
    }
    p:nth-child(1){
      display: block;
    }
    p:nth-child(2){
      display: block;
    }
    p:nth-child(3){
      display: block;
    }
    p:nth-child(3):after{
      content: ("...");
    }
    user-select: none;
    color: $text2;
    word-break: break-all;
  }

}
.link{
  text-decoration: none !important;
}
