.myCustomLabelClass,
.myCustomTitleClass {
    color: rgba(0, 0, 0, 0.87) !important;
}

.myCustomInputClass {
    padding: 0px;
}

.justifyCheckBoxes {
    display: flex;
    justify-content: space-around;
}

.textChange {
    color: rgba(0, 0, 0, 0.87);
}

.mySubtitleClass {
    color: green;
}