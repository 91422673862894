@import "../../UIKit/colorVars";
@import "../../UIKit/boxShadows";

.switch {
	position: relative;
	display: inline-block;
	user-select: none;
	box-sizing: content-box;
	background-clip: content-box;

	.slider {
		position: absolute;
		cursor: pointer;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: background-color 0.4s ease 0s, box-shadow 0.4s ease 0s, border-color 0.4s ease 0s;
	}

	.slider:before {
		position: absolute;
		content: "";
		transition: 0.4s ease;
		border-radius: 50%;
	}

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	&.bigSwitch {
		width: 34px;
		height: 14px;

		.slider {
			background: rgba(255, 255, 255, 0.32);
			border-radius: 7px;
		}

		.slider:before {
			left: -2px;
			height: 20px;
			width: 20px;
			background: #9e9e9e;
			box-shadow: $material1;
		}

		input {
			&:checked + .slider:before {
				transform: translateX(17px);
				background-color: #F4C00D;;
			}
			&:checked + .slider {
				background-color: rgba(244, 192, 13, .38);;
			}
			&:checked + .slider {
				transition: background-color 0.4s ease 0s, box-shadow 0.4s ease 0s, border-color 0.4s ease 0s;
			}
		}
	}

	&.smallSwitch {
		width: 28px;
		height: 16px;

		.slider {
			background: #e0e0e0;
			border-radius: 8px;
		}

		.slider:before {
			left: 3px;
			height: 10px;
			width: 10px;
			background: $white1;
		}

		input {
			&:checked + .slider:before {
				transform: translateX(12px);
			}
			&:checked + .slider {
				transition: background-color 0.4s ease 0s, box-shadow 0.4s ease 0s, border-color 0.4s ease 0s;
				background: #070e14;
			}
		}
	}
}
