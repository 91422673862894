@import "../../UIKit/adaptiveVars";

.filterWrap {
  // width: 288px;
  height: 64px;
  background: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 0 12px;
  
  .inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #424242;
    width: 264px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #f4c00d;
    box-shadow: 0px 0px 4px #f4c00d;
    padding: 0 12px 0 17px;

    @media (max-width: $xs425){
      width: 100%;
    }

    .input {
      background: #424242;
      border: none;
      font-size: 16px;
      color: #ffffff;
    }
    .iconWrap {
      &:hover {
        border-radius: 0;
        background: inherit;
      }
    }
  }
  .iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.12);
    }
  }
  .InboxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 264px;
    width: 100%;
    height: 40px;
    padding: 0 12px 0 17px;
  }
  .showPassBtn {
    height: 20px;
    width: 20px;
  }
  .inbox {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
  }
}
