@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.filterWrap {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 24px 0;
  .filter {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    cursor: pointer;

    @media (max-width: $xs425) {
      margin-right: 14px;
    }
  }
  .activeFilter {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    border-bottom: 1px solid #ffffff;

    @media (max-width: $xs425) {
      margin-right: 14px;
    }
  }
}
