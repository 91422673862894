$radio-size: 20px;
$radio-animation-ripple: 700ms;
$radio-animation-check: 0.3s;
$lightbg-text: rgba(0, 0, 0, 0.84);
$checked-colour: #2faef8;

.radio {
  display: inline-block;
  padding: 10px 20px;
  transform: translateZ(0);
  label {
    cursor: pointer;
    padding-left: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  // Hide native radio
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
  }

  .title {
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }

  .radioMaterial {
    vertical-align: text-top;
    position: relative;
    top: 1px;
    &:before {
      position: absolute;
      left: 7px;
      top: 6px;
      content: "";
      background-color: rgba(#b4182d, 0.8);
      height: 4px;
      width: 4px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      //transform: scale3d(2.3, 2.3, 1);
    }

    .check {
      position: relative;
      display: inline-block;
      width: $radio-size;
      height: $radio-size;
      border: 2px solid;
      border-radius: 50%;
      overflow: hidden;
      z-index: 1;
    }
    .check:before {
      position: absolute;
      content: "";
      transform: rotate(45deg);
      display: block;
      margin-top: 3px;
      margin-left: 3px;
      width: 10px;
      height: 10px;
      background: transparent;
      border-radius: 50%;
      box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
        0 0 0 0 inset;
    }
  }

  input[type="radio"]:focus + .radioMaterial .check:after {
    opacity: 0.2;
  }
  input[type="radio"]:checked + .radioMaterial .check {
    border-color: #b4182d;
  }
  input[type="radio"]:checked + .radioMaterial .check:before {
    background: #b4182d;
  }
  input[type="radio"]:checked + .radioMaterial:before {
    animation: rippleOn $radio-animation-ripple forwards ease-out;
  }

  // Ripple effect on click
  input[type="radio"]:checked + .radioMaterial .check:after {
    animation: rippleOn $radio-animation-ripple forwards ease-out;
  }
  &.disabledRadio {
    opacity: 0.38;
  }
}

// Animations
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }

  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0px 0px 0 0px inset;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
