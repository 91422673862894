@import "../../../UIKit/colorVars";

.wrapper {
  background-color: $black4;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 0;
  .item {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    color: $text2;
  }
}
