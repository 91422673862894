@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.uploadPopup {
  width: 496px;
  @media (max-width: 496px) {
    width: 100%;
  }
  .videoPlayer {
    height: 100px;
  }
}

.uploadMediaBtn {
  min-width: 100px;
  width: 100px;
  height: 100px;
  border: 1px dashed #babdc2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
  &.emptyMedia {
    margin-left: 0;
  }
  svg {
    display: block;
    width: 46px;
    height: auto;
  }
  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
.uploadMediaContent {
  margin: 20px 0;
  &.withError {
    margin-top: 0;
  }
  .photoGallery {
    flex: auto 1 0;
  }

  .maxWeight {
    color: $black1;
    font-size: 14px;
    text-align: center;
  }
  .types {
    color: $black1;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.newMediaWrap {
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style:scrollbar;
  -ms-scrollbar-arrow-color: white;
  scrollbar-track-color: white;
  scrollbar-base-color: black;
  .loaderContainer {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .uploadedPhotoItem {
    margin-left: 16px;
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    margin-bottom: 8px;
    padding-top: 0 !important;

    &:first-child {
      margin-left: 0;
      height: 100px;
      padding: 0;
    }
  }
}

.uploadVideoItem {
  min-width: 100px;
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  overflow: hidden;
  margin-left: 16px;

  .videoPlayer {
    height: 100px;
  }

  &:first-child {
    margin-left: 0;
  }
}
.popupBtns {
  width: 87px !important;
  height: 40px !important;
  &:last-child {
    color: $white1;
  }
}

.errorMessage {
  display: block;
  padding: 8px 0px 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  span {
    display: block;
  }
  &.onlyErrorMessage {
    margin-top: 28px;
  }
}

.newMediaWrap::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}


.newMediaWrap::-webkit-scrollbar  {

  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  height: 8px;
  background-clip: content-box;
}

.newMediaWrap::-webkit-scrollbar-thumb   {
  background: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  background-clip: content-box;
}
