@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.Wrapper {
  background-color: #212121;
  .videoPlayer {
    border-top: 1px solid #ffffff;
    padding: 24px;
  }
  .photo {
    max-height: 488px;
    margin: 0 auto;
  }
}
.headerButton {
  min-width: 151px !important;
}

.cardsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 12px 0;
  margin: 0 -20px;

  @media (max-width: 945px) {
    min-height: 1000px;
  }
  @media (max-width: 730px) {
    min-height: 1350px;
  }
  @media (max-width: 495px) {
    min-height: 2100px;
  }

  @media (max-width: $md) {
    justify-content: space-between;
  }
  @media (max-width: $sm) {
    justify-content: center;
  }

  .cardWrap {
    margin: 12px;
    width: 208px;
    height: 298px;
    position: relative;
    cursor: pointer;

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
      0px 0px 2px rgba(0, 0, 0, 0.14);

    &:hover {
      box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2),
        0px 5px 22px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.14);
    }

    .title {
      display: flex;
      justify-content: center;
      height: 90px;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
    }

    .Circle {
      position: absolute;
      height: 20px;
      text-align: center;
      width: 20px;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }
  }
}
