@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.msgCardWrap {
  // width: 286px;
  height: 66px;
  background: #212121;
  // background: #f4c00d;
  // margin-bottom: 24px;
  cursor: pointer;

  .userBar {
    display: block;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cardImg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 208px;
    height: 24px;
    position: relative;

    .nameWrapper {
      max-width: 45%;
      .name, .subname, .nameWithSubname {
        font-size: 16px;
        margin-left: 12px;
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .nameWithSubname {
        margin-bottom: 12px;
      }
      .subname {
        position: absolute;
        font-size: 12px;
        bottom: -8px;
        max-width: 75%;
      }
    }
  }

  .vectorWrap {
    display: flex;
    padding: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);

    .visitTime {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
      font-size: 11px;
      // margin-right: 8px;
    }
  }

  .msgWrap {
    font-size: 14px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 12px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;

    .msg {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .noMessages{
        font-style: italic;
        font-weight: 100;
      }
    }
    .msgCount {
      width: 16px;
      height: 16px;
      background: #b4182d;
      color: white;
      border-radius: 50%;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
