@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

nav {
  @media (max-width: $sm) {
    width: 100%;
  }
}

.menuWrap {
  display: flex;
  align-items: center;
  @media (max-width: $sm) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .menuItem {
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    a {
      position: relative;
      z-index: 1;
    }

    &:hover {
      &::before {
        display: block;
      }
    }
    @media (max-width: $sm) {
      margin-right: 0;
      width: 26%;
      text-align: center;
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      @media (max-width: $sm) {
        margin-bottom: 29px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: calc(50% - 16px);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.08);
      display: none;
      z-index: 0;
    }

    a {
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
    }
  }
}