@import "../../UIKit/adaptiveVars";

.wrapper {
  .departmentsWrapper {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 24px;

    @media (max-width: $lg) {
      padding: 0 16px;
    }

    @media (max-width: $xs425) {
      padding: 0;
    }
  }

  .showMoreBtn {
    margin-top: 16px;
    text-align: right;

    @media (max-width: $sm768) {
      padding-right: 16px;
    }
  }
}
