.timePicker {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  .timePickerInputsWrap{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;

    .dot  {
      color: white;
      position: absolute;
      left: 49%;
      top: 37px;
    }
    .timePickerInput {
      width: 48%;
    }
  }
  .timePickerElement {
    position: absolute;
    top: 78px;
  }
}
