@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.substrate {
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	overflow: auto;
	@media (max-width: $lg) {
		background: #070e14;
		height: calc(100vh - 56px);
		top: 56px;
	}
.player{
	//display: table-header-group;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100% !important;
	padding: 0 !important;
}
	.wrapper {
		max-width: 1200px;
		max-height: 674px;
		margin: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);

		@media (max-width: $lg) {
			max-height: 90vh;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		video {
			outline: none;
		}

		.topPanel {
			background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 2;
			opacity: 0;
			transition: all 1s;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0 24px;
			@media (max-width: $lg) {
				position: fixed;
				left: 0;
				width: 100vw;
				top: 0;
				background: transparent;
			}

			.col {
				height: 80px;
				width: 50%;
				display: flex;
				align-items: center;
				&:last-child {
					justify-content: flex-end;
				}
			}

			.videoName {
				color: #fff;
			}

			.deleteBtn {
				margin-left: 16px;
				cursor: pointer;
				@media (max-width: $lg) {
					display: none;
				}
				&.tablet {
					display: none;
					@media (max-width: $lg) {
						display: block;
						margin-right: 35px;
					}
				}
			}

			.closeBtn {
				z-index: 1;
				cursor: pointer;
			}
		}
		.visible {
			opacity: 1;
		}
	}
}
