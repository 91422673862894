@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.editorWrap {
	display: block;
	.editor {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		background: #424242;
		border: 1px solid rgba($white1, 0.7);
		border-radius: 2px;
		padding: 0px 16px 8px;
		position: relative;

		&.errorEditor {
			border-color: $red4;
		}

		.resize {
			position: absolute;
			right: 4px;
			bottom: 4px;
		}
	}

	.skillItem {
		display: flex;
		align-items: center;
		background: $white1;
		border-radius: 16px;
		padding: 4px 9px 4px 16px;
		margin-right: 8px;
		margin-top: 8px;

		.title {
			color: $text;
			line-height: 24px;
			padding-right: 10px;
			@media (max-width: $md) {
				font-size: 14px;
			}
		}
		.deleteIcon {
			cursor: pointer;
		}
	}

	.form {
		display: block;
		width: 100%;
	}

	.input {
		input {
			border: none;
			padding-left: 0;
		}
	}

	.error {
		display: flex;
		margin-top: 4px;
		svg {
			margin-top: 3px;
		}
		.text {
			padding-left: 6px;
		}
	}
}
