@import "../../../../../UIKit/colorVars";
@import "../../../../../UIKit/adaptiveVars";
.infoSection {
  &Item {
    margin-bottom: 24px;

    &Title{
      display: flex;
    }
    @media (max-width: $md) {
      align-items: end !important;
    }
  }
  &Icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    margin-right: 19.5px;
  }
  &Title{
    color: $text2;
    font-size: 18px !important;
    display: inline-block;
  }
  &Value {
    padding-left: 24px;
    word-break: break-word;
    &Bold{
      font-weight: bold;
    }
    color: $white1;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    @media (max-width: $sm) {
      padding-left: 16px;
      font-size: 16px;

      max-width: 119px;
    }
  }
}
