.calendar {

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;

  .calendarInputsWrap{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .dot  {
      position: absolute;
      left: 49%;
      top: 50%;
    }
    .calendarInput {
      width: 48%;
    }
  }
  .calendarElement {
    position: absolute;
    top: 78px;
  }
}

.withoutError {
  height: 74px;
}
