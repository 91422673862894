@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

  .wrapper {
    .headerWrapp{
      display: flex;
      justify-content: space-between;
    }
    padding: 16px;
    @media (max-width: $sm) {
      padding: 0 16px;
    }
    background-color: #212121;;
    @media (max-width: $md) {
      padding: 16px;
    }

    .breamcrumbs {
      &Link {
        color: $text2;
      }

      padding: 0 0 24px 0;

    }

    .avatar {
      margin: 16px 16px 20px 0;
    }
  }
.sectionWrapper{
  display: flex;

  flex-direction: column;
  .expiration {
    position: relative;
  }

  .avatarWrapper{
    display: flex;

    justify-content: space-between;

    .avatarHr {
      display: none;

      @media (min-width: $md+1) {
        display: block;
        border-top: 1px solid $gray10;
        margin-bottom: 60px;
      }
    }
  }
  .peerWrapper {
    display: flex;
    overflow-x: auto;
  }
  .inviteLabel {
    font-size: 14px;
    color: $text2;

    margin-bottom: 16px;

  }
  .inviteWrapper {
    width: 398px;
    display: inline-block;
    margin-bottom: 16px;
  }
  .inviteInputs {
    display: flex;

  }

  .removeInvite {
    margin-left: 21px;
    min-height: 24px;
    min-width: 24px;
    margin-top: 15px;
  }

  //.inviteInputs:nth-child(1){
  //  user-select: none;
  //  :hover {
  //    user-select: none;
  //  }
  //  :after{
  //    user-select: none;
  //    display: none;
  //  }
  //  .removeInvite {
  //    user-select: none;
  //    cursor: default;
  //    &Icon {
  //      display: none;
  //      user-select: none;
  //    }
  //  }
  //}

  .addNewLink {
    text-align: right;
    width: 100%;
    padding-right: 45px;
    padding-bottom: 16px;
  }
  .inviteSection {
  }
}
.title{
  color: $white1;
  word-break: break-word;
}

  .inputsWrapper {

    margin-top: 20px;
    .companyInfo{
      display: flex;
      align-items: center;
    }
    .input {
      input {
        background-color: $gray10;
      }
    }
    .dropdown{
      margin-top: 6px;
    }
    .textArea{
      min-height: 107px;
    }
    .description {
      color: red;
    }
    .checkBoxWrap {
      margin-left: 24px;
      margin-top: 28px;
      color: $white1;
      font-size: 16px;
    }
  }


.buttonsWrapper{
  border-top: 1px solid $gray10;
  margin-top: 58px;
  padding-top: 12px;
 @media (max-width: $sm) {
   padding-bottom: 12px;
 }

}
.saveButton {
  margin-left: 16px !important;
}
.fileInput {
  display: none;
}

.loadingWrap {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(33,33,33,0.8);
}

.deleteIcon {
  color: white;
}

.btn{
  width: 160px !important;
  height: 40px !important;
  &:first-child{
    margin-right: 16px !important;
  }
  @media (max-width: $sm) {
    width: calc(50% - 4px) !important;
    margin-right: 0 !important;
  }
}

.btnsWrap {
  @media (max-width: $sm) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

