@import "../../UIKit/adaptiveVars";

.signInWrap {
	min-height: 650px;
	display: flex;
	background: url('../../images/bg/sign-in-pattern.jpg') no-repeat center / cover;
	width: 100%;
	justify-content: center;
	@media (max-width: $md) {
		background: none;
	}

	.formWrap {
		margin-top: 50px;
		width: 464px;
		height: 309px;
		display: flex;
		align-content: center;
		align-items: center;

		@media (max-width: $md) {
			width: 100%;
			height: 100%;
			margin-top: 0px;
		}

		.inlineWrap {
			display: flex;
			align-items: center;
		}

		.logoWrap {
			width: 110px;
			height: 109px;
			flex-shrink: 0;
			float: left;
			margin-right: 50px;
			@media (max-width: $md) {
				display: none;
			}

			img {
				flex-shrink: 0;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
}
