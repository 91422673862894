@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";
.form {
  .title {
    color: $white1;
    margin-bottom: 24px;
  }
  // .inlineWrap {
  // 	display: flex;
  // 	justify-content: space-between;
  // 	flex-wrap: wrap;
  // 	@media (max-width: $sm) {
  // 		flex-direction: column;
  // 		justify-content: flex-start;
  // 	}
  // }
  .field,
  .passField {
    width: 100%;
    // @media (max-width: $sm) {
    // 	width: 100%;
    // }
    // &:first-child {
    // 	margin-bottom: 16px;
    // }
    // @media (max-width: $sm) {
    // 	margin-bottom: 16px;
    // }

    input {
      // border: none;
      border: 1px solid rgba(255, 255, 255, 0.7);
    }
  }
  .dropdownField {
    > div:nth-child(2) {
      background-color: $gray10;
    }
  }
  // .passField:last-child {
  // 	@media (max-width: $sm) {
  // 		margin-bottom: 0px;
  // 	}
  // }
}
