@import "../../UIKit/adaptiveVars";

.pageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3c3c3c;
  padding: 24px 0 40px 0;

  @media (max-width: $xs425){
    padding: 0px;
  }
}
