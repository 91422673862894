@import "../../../UIKit/colorVars";

.wrapper {
  display: inline-block;
  width: 58px;
  height: 62px;
  margin-right: 31px;
.photoWrapper {
  position: relative;
  .avatar {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .crossIcon {
    position: absolute;
    top: -5px;
    right: -20px;
  }
}
.name {
  margin-top: 8px;
  text-align: center;
  color: $text2;
  font-size: 12px;
  line-height: 14px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

}
