@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";
.wrapper {
  padding: 12px 24px;
  border-bottom: 1px solid $white1;
  justify-content: space-between;
  position: relative;

  &WithApply {
    @media (max-width: 767px) {
      flex-wrap: nowrap !important;
      flex-direction: column-reverse;
    }
  }
}

.avatarWrap {
  height: 40px;
  max-width: 40px !important;
  width: 100%;
  flex-basis: 16% !important;

  @media (min-width: $sm768) {
    height: 64px;
    max-width: 64px !important;
    width: 100%;
  }

  .avatar {
    min-width: 40px;
    width: 100%;
    min-height: 40px;
    height: 100%;

    @media (min-width: $sm768) {
      min-width: 64px;
      width: 40px;
      min-height: 64px;
    }
  }
}

.headerWrapper {
  @media (max-width: $sm768) {
    max-width: 82% !important;
    flex-basis: 82% !important;
  }

  .header {
    padding-left: 16px;

    .titleWrap {
      .headerTitle {
        font-size: 28px !important;
        line-height: 1.4;
        color: $white1;
        display: flex;
        align-items: center;

        @media (max-width: $xl) {
          font-size: 18px !important;
        }

        @media (max-width: $md) {
          font-size: 18px !important;
        }

        @media (max-width: $xs425) {
          display: flex;
          justify-content: space-between;
          font-size: 18px !important;
        }
      }
    }

    .headerSubTitle {
      word-break: break-word;
      color: $text2;
      font-size: 16px;

      @media (max-width: $md) {
        font-size: 14px !important;
        letter-spacing: 0 !important;
      }
    }

    &Icon {
      margin-left: 16px;

      @media (max-width: 767px) {
        margin-left: 0;
        position: absolute;
        right: 15px;
        top: 38px;
      }
    }

    .headerIconWithApply {
      margin-left: 16px;

      @media (max-width: 767px) {
        margin-left: 0;
        position: absolute;
        right: 23px;
        top: 110px;
      }
    }
  }
}

.buttonWrapApply {
  text-align: right;
  align-items: center !important;

  @media (max-width: 767px) {
    height: 100%;
    min-width: 100% !important;
    margin-bottom: 20px !important;
    flex-basis: 100% !important;
  }

  .btnWrapper {
    width: 100%;
    .applyButton {
      min-width: 151px;

      @media (max-width: 767px) {
        max-width: unset !important;
        min-width: 100% !important;
        width: 100% !important;
      }
      @media (max-width: $xs425) {
        width: 100%;
        max-width: unset !important;
      }

      &Text {
        color: $text2;
        max-width: 151px;
        min-width: 151px;
        font-size: 12px;
        margin-top: 8px;

        @media (max-width: 767px) {
          margin: 8px auto 0;
        }
      }
    }
  }
}

.buttonWrap {
  text-align: right;
  max-width: 30px !important;
  align-items: center !important;

  @media (max-width: 767px) {
    position: absolute;
    top: -1px;
    right: 15px;
  }
}

.headerButton {
  max-width: 151px !important;
  width: 100%;
  align-items: center;

  @media (max-width: $sm) {
    max-width: 100% !important;
    width: 100% !important;
  }

  @media (max-width: $md) {
    width: 100% !important;
  }
}

.companyName {
  color: $gold;
}

.location {
  &:before {
    content: "•";
    display: inline-block;
    margin: 0 5px;
  }

  @media (max-width: $xs425) {
    width: 100% !important;
    display: block;

    &:before {
      display: none;
    }
  }
}

.applyButtonText {
  font-size: 12px;
  color: $text2;
  text-align: center;
  margin-top: 8px;

  @media screen and (max-width: $xs425) {
    width: 100%;
  }
}
