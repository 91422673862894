@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.wrapper {
  height: 640px;
  background: url("../../../images/home/banner/bigBannerMobile.png") no-repeat
    100%;
  background-size: cover;
  @media (min-width: $sm768) {
    height: 648px;
    background: url("../../../images/home/banner/bigBannerTablet.png") no-repeat;
    background-size: cover;
  }
  @media (min-width: $md1440) {
    height: 702px;
    background: url("../../../images/home/banner/bigBanner1440.png") no-repeat;
    background-size: cover;
  }
  @media (min-width: $lg1920) {
    height: 701px;

    background: url("../../../images/home/banner/bigBanner.png") no-repeat;
    background-size: cover;
  }
  .title {
    font-family: Merriweather;
    background: rgba(0, 0, 0, 0.6);
    color: $white1;
    font-size: 24px;
    line-height: 150%;

    max-height: 30%;
    overflow: hidden;

    margin: 130px auto 0;

    padding: 0 16px;

    width: calc(100% - 32px);

    p{
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
    }

    @media (max-width: 767px) {
      line-height: 38px;
      p{
      -webkit-line-clamp: 5;

      }
    }

    @media (min-width: $sm768) and (max-width: 1439px) {
      width: 469px;
      margin-top: 130px;
      font-size: 36px;
      margin-left: 16px;

      line-height: 48px;
      
      p{
      -webkit-line-clamp: 4;
      }
    }
    
    @media (min-width: $md1440) {
      width: 629px;
      font-size: 48px;
      margin-left: 136px;
    }

    @media (min-width: $lg1920) {
      max-height: 216px;
      overflow: hidden;
      background-size: cover;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 314px;
    margin-top: 24%;
    @media (min-width: $sm768) {
      margin-top: 241px;
    }
    .readMore {
      width: 281px !important;
    }
  }
}
