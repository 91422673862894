@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.profileWrap {
  padding-top: 16px;
  padding-bottom: 80px;
  position: relative;
  @media (max-width: $xl) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: $lg) {
    justify-content: center;
    padding-top: 64px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: $md) {
    padding-top: 16px;
  }
  .breadcrumbs {
    margin-top: 0;
    margin-bottom: 16px;

    @media (max-width: $md) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  nav {
    margin-bottom: 16px;
    color: $text2;

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .profileLeftColumn {
    width: 282px;

    @media (max-width: $lg) {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
    }
    @media (max-width: $md) {
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      margin: auto;
    }
    @media (max-width: $sm) {
      margin: auto;
    }
  }

  .companyInfo {
    @media (max-width: $md) {
      max-width: 348px;
      margin: auto;
    }
    @media (max-width: $xs425) {
      max-width: 100%;
    }
  }

  @media (max-width: $lg) {
    width: 100%;
    margin-right: 0;
    margin-top: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: $md) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: auto;
  }
  @media (max-width: $sm) {
    width: 328px;
    margin: auto;
  }
  .alert {
    position: absolute;
    right: 0;
    top: 8px
  }
}

.profileRightColumn {
  width: calc(100% - 306px);
  @media (max-width: $lg) {
    width: 100%;
  }
  @media (max-width: $md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.videosWrap {
  margin-top: 24px;
  @media (max-width: $lg) {
    margin-bottom: 40px;
    margin-top: 96px;
  }
  @media (max-width: $md) {
    margin-top: 40px;
  }
}
.galleryWrap {
  @media (max-width: $lg) {
    margin-top: 24px;
    margin-bottom: 0;
    margin: 24px 16px 0 16px;
  }
}
.tablet {
  display: none;
  @media (max-width: $lg) {
    display: block;
  }
  @media (max-width: $md) {
    display: none;
  }
}
.departmentsContainer {
  @media (max-width: $xl) {
    margin-left: 0;
  }
}
