@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";
@import "../../../UIKit/boxShadows";

.notifications {
  width: 468px;
  max-height: 540px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.25s ease;
  background: white;
  box-shadow: $material5, 0px 6px 10px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  display: none;
  flex-direction: column;

  &Visible {
    display: flex;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  @media (max-width: $xs425) {
    width: 100%;
    position: fixed;
    top: 56px;
    right: 0;
    display: block;
    overflow: auto;
    height: 100%;
    max-height: calc(100% - 56px);
  }

  .header {
    padding: 0 8px;
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $xs425) {
      position: absolute;
      width: 100%;
      height: 40px;
    }

    & > div {
      display: flex;
    }
  }

  .cardsWrapper {
    max-height: 460px;
    overflow-y: auto;

    @media (max-width: $xs425) {
      max-height: unset;
      height: fit-content;
      margin-top: 40px;
    }

    .cardWrapper {
      margin-bottom: 2px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background: rgba(0, 0, 0, 0.38);
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: rgba(0, 0, 0, 0.38);
      border-radius: 4px;
    }
  }

  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      font-size: 16px;
      font-weight: 400;
      color: $gold;
      text-decoration: none;
      margin-right: 16px;
    }
  }
}
