@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.photoGalleryItem {
  width: 100%;
  height: auto;
  padding-top: 96%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &:hover .deletePhoto {
    display: block;
    cursor: pointer;
  }

  .handler {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .deletePhoto {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    height: 16px;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    z-index: 3;

    @media (max-width: $md) {
      display: block;
    }
  }
  &.empty {
    background: #757575;
    width: 100%;
    height: 100%;
    padding: 26% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    @media (max-width: $lg) {
      height: 192px;
      padding: 0;
    }
    @media (max-width: $md - 1) {
      height: 97px;
    }

    .icon {
      path {
        fill: rgba($white1, 0.7);
      }
    }
    .title {
      color: $white1;
      margin-top: 8px;
    }
  }
}
