
.wrapper {
    color: black;
    padding: 40px;
    max-height: 600px;
    max-width: 800px;
    overflow-y: auto;
    margin-right: -23px;
}

.title {
    text-align: center;
}

.subtitle {
    text-align: center;
    margin-bottom: 30px;
}

.parag {
    margin-bottom: 20px;
}

.paragTitle {
    font-weight: bold;
}

