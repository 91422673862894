@import "../../UIKit/colorVars";

.emojiWrap {
  width: 123px;
  .wrap {
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px;
  }
  .likeCount {
    display: flex;
    color: #ffffff;
    font-size: 12px;
    align-items: center;
    p {
      margin-left: 6px;
    }
    span {
      cursor: pointer;
    }
  }
}

.activeEmoji {
  *,
  svg,
  * svg {
    fill: $gold;
    fill-opacity: 1;
  }
}
