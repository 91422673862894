@import "../../UIKit/adaptiveVars";
@import "../../UIKit/colorVars";

.topOpportunities {
  background: url("../../images/bg/topOpportunitiesBg.jpg") no-repeat;
  background-size: cover;
  color: white;
  padding: 40px 0 52px 0;
  overflow-x: hidden;
  background-size: cover;
  overflow-y: hidden;
  height: 100%;

  .topLine {
    display: flex;
    position: relative;
    margin-bottom: 16px;
    .line {
      height: 2px;
      background: white;
      margin-top: 18px;
      flex-grow: 2;
    }
    h3 {
      width: 1200px;
      flex-grow: 2.2;

      @media (max-width: $sm768) {
        padding-left: 24px;
      }
      @media (max-width: $xs425) {
        padding-left: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background: white;
        margin-left: 5px;
      }
    }
  }

  .sectionContent {
    max-width: 1200px;
    width: 100%;
    margin: 24px auto;

    @media (max-width: $sm768) {
      padding: 0 24px;
    }

    @media (min-width: $sm768) {
      padding: 0 16px;
    }

    @media (min-width: $md1440) {
      padding: 0;
    }
    .opportunitiesWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: $xs425) {
        justify-content: center;
      }

      & > div {
        margin: 0 0 24px;
        width: 384px;
        width: calc((100% - 48px) / 3);

        @media (max-width: $md1024) {
          width: calc((100% - 24px) / 2);
        }

        @media (max-width: $xs425) {
          width: 100%;
        }
      }
      & > div:nth-child(3) {
        margin-right: 0px;
      }

      .btnWrapper {
        width: 100%;
        text-align: center;

        @media (max-width: $sm768) {
          width: 100%;
        }
      }
    }
  }
}
