@import "../../../../../UIKit/adaptiveVars";
.field {
	display: inline-block;
	width: 398px;
	margin-right: 20px;
	@media (max-width: $md) {
		margin-right: 18px;
		width: calc(100% - 120px);
	}
	&:not(:last-child) {
		margin-bottom: 8px;
	}
}

.showField {
	height: 48px;
	width: 450px;
	line-height: 48px;
	display: inline-block;
	line-height: 1.5;
	@media (max-width: $sm){
		height: 100%;
		line-height: 35px;
		width: 280px;
	}
	@media (min-width: $sm768){
		height: 100%;
		width: 450px;
		line-height: 35px;
	}

}

.wrap {
	width: 541px;
	//display: flex;
	//justify-content: flex-start;
	//align-items: baseline;
	@media (max-width: $md) {
		width: 100%;
	}
}
.showWrap {

	align-items: center;
}
 .switchWrap{
	 padding-top: 7px;
	 vertical-align: top;
	 display: inline-block;
}

.iconWrap {
	padding-top: 15px;
	vertical-align: top;
	display: inline-block;
	text-align: center;
	width: 25px;
	height: 24px;
	margin-right: 40px;
	&Show{
		padding-top: 7px;
	}
	@media (max-width: $md) {
		margin-right: 18px;
	}
	.icon {
		width: 20px;

	}
}





