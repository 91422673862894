@import "./mainTypography";
@import "./titleTypography";
@import "./noteTypography";
@import "./adaptiveVars";
@import "./colorVars";
@import "./cards";
@import "./mixins";
@import "./boxShadows";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input#search::-ms-clear,
input#search::-ms-reveal {
  display: block;
}

html {
  height: -webkit-fill-available;
}

body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
}

img {
  max-width: inherit;
  display: block;
}

ul {
  list-style: none;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

button {
  cursor: pointer;
}

.marriWeather {
  font-family: "Merriweather", sans-serif;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: auto;

  .content {
    display: flex; // don't use for children elements https://developer.mozilla.org/ru/docs/Web/CSS/flex
    align-items: stretch;
    flex: 1 0 auto;
    min-height: calc(100vh - 56px);
    height: auto;
    margin-top: 56px;

    @media (max-width: $xxxl) {
      min-height: calc(100vh - 56px);
      height: auto;
    }
  }
}

.container {
  max-width: 1200px;

  @media (max-width: $xxl) {
    width: 83.33%;
  }
}

.smallContainer {
  width: 54.5%;
  max-width: 1044px;

  @media (max-width: $xxl) {
    width: 72.22%;
  }
}

.container,
.smallContainer {
  margin: 0 auto;

  @media (max-width: $xl) {
    width: calc(100% - 24px * 2);
    max-width: 100%;
  }

  @media (max-width: $sm) {
    width: calc(100% - 16px * 2);
  }

  &.fullWidth {
    @media (max-width: $xl) {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }
}

header,
section,
footer {
  width: 100%;
}

//***********  RESET AUTOFILL FIELDS  *************
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 0 0 1000px #424242 inset;
  box-shadow: 0 0 0 1000px #424242 inset;
}

// Global style which uses for styling image cropper
.cropper-view-box {
  border-radius: 50%;
}

// Global styles for video player
.video-react-big-play-button-left {
  display: none !important;
}

.video-react-playback-rate,
.video-react-icon-replay-10,
.video-react-icon-forward-30 {
  @media (max-width: 1439px) {
    display: none !important;
  }
}

.goldLink {
  color: $gold;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
}

.iconWrap {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::after {
    display: none;
  }

  &:hover {
    &::after {
      content: "";
      position: absolute;
      left: -4px;
      top: -4px;
      width: 32px;
      height: 32px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 50%;
      display: block;
    }
  }

  .icon {
    z-index: 5;

    path {
      fill: $white1;
    }
  }
}
