@import "../../../UIKit/index";

.articleBox {
  max-width: 384px;
  height: 504px;
  color: #ffffff;
  background: #424242;
  position: relative;
  cursor: pointer;
  flex: 2;

  @media (max-width: $md1024) {
    display: none;
  }

  .image {
    height: 50%;
  }

  .authorBlock {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: flex-end;
    margin: 0 24px;

    img {
      border: 4px solid #ffffff;
      border-radius: 50%;
      width: 59px;
      height: 59px;
      margin-right: 16px;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .content {
    padding: 0 24px;
    h2 {
      font-family: Merriweather;
      font-weight: bold;
      font-size: 21px;
      line-height: 32px;
      padding: 40px 0 4px 0;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
    }
    p {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
    }
  }
}
