@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.skillsWrap {
	.editSkillsWrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.editor {
		margin-top: 16px;
		width: 588px;
		@media (max-width: 1160px) {
			width: 534px;
		}
		@media (max-width: $md) {
			width: 100%;
		}
	}

	.btnsWrap {
		margin-top: 24px;
		@media (max-width: $sm) {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}

	.btn {
		width: 160px;
		height: 40px;
		&:first-child {
			margin-right: 16px;
		}
		@media (max-width: $sm) {
			width: calc(50% - 4px);
		}
	}

	.skillsTextWrap {
		display: flex;
		justify-content: flex-end;
		margin-top: 28px;
		@media (max-width: $md) {
			margin-top: 16px;
		}
	}

	.skills {
		width: 588px;
		@media (max-width: 1160px) {
			width: 534px;
		}
		@media (max-width: $md) {
			width: 100%;
		}
		span {
			padding-right: 8px;
			display: inline-block;
			&:last-child {
				padding-right: 0;
			}
		}
	}
}
