.buttonWrapper {
  padding: 8px;
  padding-left: 16px;
  width: 100%;

}

.button {
  color: #ddd;
  font-size: 18px;
  padding-top: 5px;
  vertical-align: bottom;
  height: 40px;
  width: 40px;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.7);
  background: transparent;
}

.button svg {
  fill: #ddd;
}

.button:hover, .button:focus {
  background: #444;
  outline: 0; /* reset for :focus */
}

.active {
  color: #6a9cc9;
}

.active svg {
  fill: #6a9cc9;
}
