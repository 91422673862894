@import "../../../UIKit/adaptiveVars";

.pagination{
  text-align: right;
  margin-bottom: 80px;
  @media (max-width: $sm768) {
    text-align: center;
  }
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
