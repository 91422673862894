// .text18r defoult

.text18m {
	font-weight: 500;
}

.text16m,
.text16r {
	font-size: 16px;
	line-height: 1.5;
}

.text18r,
.text16m,
.text16r {
	.marriWeather {
		font-family: "Merriweather", sans-serif;
	}
}

.text16m {
	font-weight: 500;
}
.text16r {
	font-weight: normal;
}
