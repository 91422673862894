@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.photoGalleryWrap {
  .titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
    }

    h5 {
      font-size: 21px;
      color: $white1;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (max-width: $xs425) {
        font-size: 16px;
      }
    }

    span {
      color: $gold;
      cursor: pointer;
    }
  }

  .galleryItem {
    padding: 0px 12px !important;
    @media (max-width: $lg) {
      padding: 0;
      background: transparent;
      width: 40%;
      flex: 0 0 40%;
      margin-bottom: 8px;
    }
    &Image {
      height: 192px;
      @media (max-width: $lg) {
        height: auto;
      }
    }
    &.empty {
      @media (max-width: $lg) {
        padding: 0;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .photoGallery {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    @media (max-width: $lg) {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-bottom: 8px;
    }
  }
  .emptyPhotoGalleryItem {
    height: 100%;
  }

  &.fullPhotoGallery {
    background: #212121;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;

    .titleWrap {
      padding: 14px 24px;
      border-bottom: 1px solid rgba($white1, 0.32);
      font-size: 21px;

      .addPhotoButton {
        width: 110px;
        height: 40px;

        span {
          color: $white1;
        }
      }
    }
    .photoGallery {
      padding: 20px 24px;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;

      @media (max-width: $lg) {
        flex-wrap: wrap;
        overflow-x: hidden;
      }
    }
    .emptyFullGallery {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: rgba($white1, 0.7);
    }
    .galleryItem {
      padding: 12px !important;
      @media (max-width: $lg) {
        width: 33%;
        flex: 0 0 33%;
        padding: 8px !important;
      }
      @media (max-width: $sm) {
        width: 50%;
        flex: 0 0 50%;
      }
    }
    .photoGalleryItem {
      padding-top: 76%;
    }
  }
  &.emptyFullPhotoGallery {
    .photoGallery {
      flex: 1;
    }
  }
}

$btnWidth: 25px;

.photoPopupWrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.87);
  z-index: 100;
  display: flex;
  align-items: center;

  @media (max-width: 1199px) {
    background: $black2;
    top: 56px;
    height: calc(100vh - 56px);
  }

  .photoPopup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    position: relative;
    overflow: hidden;
    width: calc(100% + (#{$btnWidth} * 2));
    @media (max-width: 1199px) {
      height: 100%;
    }
  }
  .imagesWrapper {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 80vh;
    @media (max-width: 1199px) {
      height: calc(100vh - 56px);
    }
  }

  .imageWrap {
    position: relative;
    min-width: calc(1200px - 80px);
    max-width: calc(1200px - 80px);
    margin-left: 40px;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2),
      0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14);
    @media (max-width: 1199px) {
      width: calc(100vw - 60px);
      margin-left: 30px;
      height: calc(100vh - 56px);
    }
    @media (max-width: $sm768) {
      min-width: 0;
    }
  }

  .imageInlineWrap {
    position: relative;
    @media (max-width: $lg) {
      position: static;
    }
  }

  .titleWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 80px;
    @media (max-width: $md - 1) {
      padding: 0 16px;
    }
    @media (max-width: 480px) {
      padding: 0 8px;
    }

    .photoName {
      display: flex;
      align-items: center;
      color: $white1;
      width: fit-content;
      max-width: 100%;

      h5 {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .deleteBtn {
        margin-left: 15px;
        cursor: pointer;
      }
    }
    .closeIcon {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  img {
    display: block;
    width: auto;
    height: auto;
    // min-width: 650px;
    min-height: 50vh;
    max-width: calc(1200px - 80px);
    max-height: 80vh;
    @media (max-width: 1199px) {
      max-width: calc(100vw - 60px);
    }
    @media (max-width: $lg) {
      min-height: 40vh;
    }
    @media (max-width: $md - 1) {
      min-height: 30vh;
      min-width: calc(100vw - 60px);
      max-height: 70vh;
    }
  }

  .prev,
  .next {
    position: absolute;
    z-index: 99999;
    width: $btnWidth;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:disabled {
      opacity: 0.5;
      cursor: default;
      &:hover {
        svg {
          opacity: 0.8;
        }
      }
    }
    svg {
      display: block;
      width: 20px;
      height: auto;
      opacity: 0.8;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  .prev {
    right: calc(100% - #{$btnWidth});
    transform: rotate(-90deg);
  }
  .next {
    left: calc(100% - #{$btnWidth});
    transform: rotate(90deg);
  }
}

.photoGallery::-webkit-scrollbar-track {
  background-color: transparent;
  height: 1px;
}

.photoGallery::-webkit-scrollbar {
  background: transparent;
  height: 1px;
  background-clip: content-box;
}

.photoGallery::-webkit-scrollbar-thumb {
  background: transparent;
  background-clip: content-box;
}
