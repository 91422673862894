@import "../../UIKit/colorVars";

.wrapper {
  position: relative;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  text-align: right;

  .threeDots {
    max-width: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .actionsList {
    position: absolute;
    background: #fff;
    padding: 8px 0;
    left: -112px;

    .action {
      min-width: 112px;
      height: 48px;
      text-align: left;
      line-height: 48px;
      padding: 0 16px;
      cursor: pointer;
      color: #000;
      white-space: nowrap;

      &:hover {
        background-color: $white3;
      }
    }
  }
}
