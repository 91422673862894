.toolbar {
  position: absolute;
  top: 21px;
  z-index: 1;
  left: 0;
  box-sizing: border-box;
  margin-top: 8px;
  order: 0;
  display: block;
  border-bottom: 1px solid rgb(198,198,198);
  height: 56px;
  margin-left: 1px;
  width: 99.7%;
  .buttonsWrapper{
    display: flex;
    justify-content: center;
  }
}

.toolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.toolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #111;
  border-width: 6px;
  margin-left: -6px;
}
