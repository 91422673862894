@import "../../UIKit/colorVars";

.fieldWrap {
  &Dark {
    .fieldName {
      color: rgba(255, 255, 255, 0.7);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .field {
      background-color: #424242;
      border: 1px solid rgba(255, 255, 255, 0.7);
      color: $white1;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .beforeIcon svg path,
    .afterIcon svg path {
      fill: $white1;
    }
    .disabled {
      background-color: #2e2e2e !important;
      color: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  &Black {
    .fieldName {
      color: $text2;
    }
    .field {
      background-color: #212121;
      border: 1px solid rgba(255, 255, 255, 0.7);
      color: $white1;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .beforeIcon svg path,
    .afterIcon svg path {
      fill: $white1;
    }
  }
  &Light {
    .fieldName {
      color: $text;
    }

    .field {
      background-color: $white1;
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.87);

      &::placeholder {
        color: rgba(0, 0, 0, 0.38);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid #ddd;
        -webkit-text-fill-color: black !important;
        -webkit-box-shadow: 0 0 0 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    .beforeIcon svg path,
    .afterIcon svg path {
      fill: rgba(0, 0, 0, 0.38);
    }

    .errorMessageWrap {
      svg {
        min-width: 14px;
        min-height: 14px;
      }
      .errorMessage {
        color: black !important;
      }
    }
  }

  &.withBeforeIcon .field {
    padding-left: 52px;
  }
  &.withAfterIcon .field {
    padding-right: 52px;
  }

  &.errorInput {
    span {
      opacity: 0.7;
    }
    .inputContainer {
      border: 1px solid $red4;
      border-radius: 2px;

      input {
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
      }
    }
  }

  .fieldName {
    display: block;
    text-align: left;
    margin-bottom: 4px;
  }

  .inputContainer {
    position: relative;
    border: 1px solid transparent;
  }

  .field {
    padding: 0 16px;
    width: 100%;
    height: 48px;
    border-radius: 2px;
    -webkit-appearance: none;
    // -webkit-border-radius: 2px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .beforeIcon,
  .afterIcon {
    position: absolute;
    // top: 12px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      height: auto;
    }
  }

  .beforeIcon {
    left: 18px;
    svg {
      width: 17px;
    }
  }
  .afterIcon {
    right: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .errorMessageWrap {
    display: flex;
    margin-top: 4px;
  }

  p.errorMessage {
    color: $white1;
    font-size: 11px;
    line-height: 16px;
    margin-left: 4px;
    text-align: left;
  }
}

.withoutError {
  height: 74px;
}
