@import "../../UIKit/adaptiveVars";

.filterWrap {
  width: 100%;
  min-height: 64px;
  background: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 24px 16px 16px;
    position: relative;

    .arrow {
      display: none;
      transform: rotate(-90deg);

      @media (max-width: $xs425) {
        display: block;
      }
    }
  }
  .imgWrap, .subname, .withSubname {
    display: flex;
    color: #ffffff;
    font-size: 21px;
    width: 80%;
    text-decoration: none;
    .name {
      font-size: 21px;
      margin-left: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .withSubname {
    padding-bottom: 20px;
  }

  .subname {
    position: absolute;
    left: 32px;
    bottom: 0;
    .name {
      font-size: 18px;
    }
  }
  .userBar {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .menuWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
