@import "./colorVars";


@mixin copied {

    position: relative;
    &:after {
      content: "Copied";
      position: absolute;
      top: -150%;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: rgba(#fff, 0.7);
      border-radius: 15px;
      padding: 0 10px;
      font-size: 14px;
      color: $black1;
      min-width: 65px;
      transition: all 0.35s ease;
    }
    &:hover{
      opacity: 1;
    }
}
