@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.filterItem:not(:last-child) {
  margin-bottom: 16px;
  @media (min-width: $xs425) and (max-width: $md1440) {
    margin-bottom: 16px;
  }
}
.filterButtonsWrap {
  display: flex;
  justify-content: flex-end;

  .filterButtonWrap {
    .filterButton {
      width: 117px;
    }
  }
  .filterButtonWrap:not(:last-child){
    margin-right: 16px;
  }
  @media (max-width: $xs425) {
    justify-content: space-between;
    .filterButtonWrap {
      width: calc(50% - 8px);
    }
    .filterButton {
      width: 100% !important;
    }
  }
}

.firstSection {
  @media (min-width: $xs425) and (max-width: $md1440) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  @media (max-width: $xs425) {
    display: block;
  }
  @media (min-width: $md1440) {
    display: block;
  }
  .filterItem {
    @media (min-width: $xs425) and (max-width: $md1440) {
      width: calc(50% - 16px);
      margin-bottom: 16px;
    }
    @media (max-width: $xs425) {
      width: 100%;
      max-width: unset;
      margin-bottom: 16px !important;
    }
    @media (min-width: $md1440) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.checkBoxSection{
  margin-bottom: 16px;
  @media (min-width: $sm768) and (max-width: $md1440) {
    display: flex;
    margin-right: 40px;
  }
  @media (min-width: $md1440) {
    display: block;
  }
  .checkBoxWrap {
    align-items: end !important;
    @media (min-width: $sm768) and (max-width: $md1440) {
      margin-right: 40px;
    }
    @media (min-width: $md1440) {
      margin-right: 0;
    }
    .checkBox {
      padding: 0 9px 9px 9px !important;
    }
  }
}
