@import "../../UIKit/colorVars";

.fieldWrap {
  .fieldName {
    display: block;
    text-align: left;
    color: $white1;
    margin-bottom: 4px;
  }

  .inputContainer {
    position: relative;
  }

  .field {
    background-color: #424242;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 0 52px 0 16px;
    width: 100%;
    height: 48px;
    color: rgba(255, 255, 255, 0.7);

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
    }
  }

  .showPassBtn {
    position: absolute;
    right: 16px;
    top: 12px;

    svg {
      display: block;
      width: 20px;

      path {
        fill: $white1;
        fill-opacity: 1;
      }
    }
  }

  .errorMessageWrap {
    display: flex;
    margin-top: 4px;
  }

  .errorMessage {
    color: $white1;
    font-size: 11px;
    line-height: 16px;
    margin-left: 4px;
    text-align: left;
  }
}

.errorInput {
  span {
    opacity: 0.7;
  }

  .inputContainer {
    border: 1px solid red;
    border-radius: 2px;

    input {
      border: none !important;
      border-radius: 0;
    }
  }
}

.Icon {
  cursor: pointer;
}
