@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.videoPlayer {
  height: 193px;
  width: 282px;

  @media (max-width: $md) {
    width: 240px;
    height: 130px;
  }
}

.emptyVideo {
  & > div {
    width: 100%;
    max-width: 282px;
    height: 172px;
    background: #757575;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    @media (max-width: $lg) {
      height: 164px;
      max-width: 240px;
    }

    @media (max-width: $md - 1) {
      height: 97px;
    }

    .icon {
      path {
        fill: rgba($white1, 0.7);
      }
    }

    .title {
      margin-top: 18px;
      color: $white1;
    }
  }

  .uploadInfoText {
    padding-left: 2px;
    font-size: 12px;
    line-height: 14px;
    color: $text2;
  }
  .uploadInfoText:not(:last-child) {
    padding-top: 5px;
  }
}

.cardWrapper {
  height: auto;
  width: 282px;

  @media (max-width: $md) {
    max-width: 240px;
    height: auto;
  }

  &.small {
    height: 100px;
    width: 100px;

    .videoPlayer {
      height: 100px;
      width: 100px !important;
    }
  }
}

.videoPlayerWrapper {
  position: relative;
  overflow: hidden;

  .substrate {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
  &:hover {
    .deleteIcon {
      visibility: visible;
    }
    .videoDuration {
      visibility: visible;
    }
  }

  .deleteIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 3;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    width: 16px;
    height: 16px;

    svg {
      position: absolute;
    }

    @media (max-width: $lg) {
      visibility: visible;
    }
  }
  .videoDuration {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    height: 24px;
    padding: 0 10px;
    position: absolute;
    bottom: 48px;
    right: 8px;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    visibility: hidden;
    @media (max-width: $lg) {
      visibility: visible;
      bottom: 8px;
    }
  }

  .videoThumbnail {
    width: 100%;
    height: 190px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: $lg) {
      height: 150px;
    }

    @media (max-width: 415px) {
      height: 190px;
    }
  }

  .videoName {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 100;
    white-space: nowrap;
    @media (max-width: $md) {
      display: none;
    }

    span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.error {
  border: 1px solid $red4;
}
p.errorMessage {
  color: $white1;
  font-size: 11px;
  line-height: 16px;
  margin-left: 4px;
  text-align: left;
}
.errorMessageWrap {
  display: flex;
  margin-top: 4px;
  top: 100%;
}
