@import "../../UIKit/index";

.articleContainer {
  max-width: 1200px;
  margin: auto;
  width: 100%;
  // margin-top: 18px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $xs425) {
    flex-direction: column;
    // padding: 0 16px;
  }

  @media (max-width: $sm768) {
    flex-direction: column;
    max-width: 722px;
  }

  main {
    background: $black4;
    max-width: 894px;
    height: fit-content;
    width: 100%;

    .mainInfo {
      padding: 0 16px;
      .articleHeader {
        color: white;
        display: flex;
        padding-bottom: 34px;
        position: relative;

        h1 {
          margin-top: 16px;
          font-family: Merriweather;
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 56px;
          max-width: 692px;
          @media (max-width: $xs425) {
            &:before {
              content: "";
              float: right;
              width: 40%;
              height: 1em;
            }
          }
          @media (max-width: $sm768) {
            &:before {
              content: "";
              float: right;
              width: 20%;
              height: 1em;
            }
          }
        }

        .labelWrapper {
          position: absolute;
          top: -4px;
          right: -16px;
          cursor: pointer;
        }
      }

      .image {
        max-width: 862px;
        //max-height: 445px;
        // height: 445px;
        height: 100%;
        width: 100%;
        margin: auto;
        margin-bottom: 56px;

        @media (max-width: $xs425) {
          margin-bottom: 16px;
        }
      }
      .metaData {
        // padding: 0 16px;
        display: flex;
        justify-content: space-between;
        justify-content: flex-end;
        margin-bottom: 24px;

        @media (max-width: $xs425) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 16px;
        }

        .mediaBtns {
          display: flex;

          & > div {
            width: 40px;
            height: 40px;

            & > button {
              width: 100%;
              height: 100%;
              min-width: 100%;
            }
            &:not(:first-child) {
              margin-left: 16px;
            }
          }
        }
      }

      .dateInfoBar {
        display: flex;
        justify-content: space-between;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        .publishDate {
          text-transform: uppercase;
          color: white;

          @media (max-width: $xs425) {
            max-width: 130px;
            font-size: 14px;
          }
        }
      }

      .bodyText {
        font-family: Merriweather;
        padding: 24px 0 16px 0;
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        font-size: 16px;
        line-height: 24px;

        iframe,
        img,
        video {
          width: 100%;
        }

        header,
        img {
          display: none;
        }
        ol,
        ul {
          margin-left: 20px;
        }
        a {
          position: relative;
          color: $gold;
          text-decoration: none;
        }
        a,
        em,
        b,
        strong,
        i {
          &.copied {
            @include copied;
            &:after {
              font-style: normal;
              top: -100%;
            }
          }
        }

        @media (max-width: $xs425) {
          font-size: 14px;
        }
      }
    }
    .shareBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
      h3 {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: white;
        font-weight: normal;
        font-size: 18px;
        line-height: 50px;
        margin-top: 16px;
      }

      .buttons {
        display: flex;
        & > div {
          width: 40px;
          height: 40px;

          & > button {
            min-width: 100%;
            width: 40px;
            height: 40px;
            // & svg {
            //   transform: scale(0.8);
            // }
          }

          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
    }
  }

  .trendings {
    width: 282px;
    @media (max-width: $sm768) {
      margin: 40px 0 80px 0;
      width: 100%;
    }

    @media (max-width: $xs425) {
      margin-bottom: 0;
      padding: 0 15px;
    }

    .trendingsList {
      display: flex;
      flex-direction: column;

      & > div {
        margin-bottom: 24px;

        @media (max-width: $xs425) {
          width: 100%;
        }
      }

      @media (max-width: $sm768) {
        flex-direction: row;
        justify-content: space-around;
        width: 720px;
        margin: auto;

        & > div:first-child {
          margin-right: 24px;
        }
      }

      @media (max-width: $xs425) {
        flex-direction: column;
        width: 100%;
      }
    }

    h2 {
      font-size: 28px;
      color: white;
      line-height: 40px;
      margin-bottom: 24px;
      @media (max-width: $sm768) {
        margin-left: 0;
      }
    }

    a {
      display: block;
      color: $gold;
      font-weight: 200;
      text-align: right;
      @media (max-width: $sm768) {
        margin-right: 55px;
      }

      @media (max-width: $xs425) {
        text-align: center;
        margin: 0;
      }
    }
  }

  .responses {
    h3 {
      color: white;
      font-size: 28px;
    }

    & > div {
      padding: 16px 0;
    }
  }
}
.quotesList {
  margin-top: 80px;
}
