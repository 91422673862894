@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.resourcesEditWrapper{
  background: linear-gradient(180deg, #494949 0%, #272727 100%);
  width: 100%;
  .wrapper{
    margin-bottom: 80px;
    .formWrapper{
      max-width: 894px;
      width: 100%;
      @media (max-width: $xl) {
        max-width: unset;
      }
    }
    .breadcrumbs {
      color: rgba($white1, 0.7) !important;
      padding: 16px 0;
      padding-left: 16px;
      @media (min-width: $md1440) {
        padding-left: 0;
      }
      .link {
        cursor: pointer;
        color: rgba($white1, 0.7);
        text-decoration: underline !important;
        &:hover {
          text-decoration: none !important;
        }
      }
      .currentLink{
        cursor: initial;
        text-decoration: none !important;
      }
    }
  }
}
