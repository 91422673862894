@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.opportunityShowWrapper {
	width: 100%;
	@media (max-width: $md1440) {
		padding: 0 24px;
	}
	@media (max-width: 767px) {
		padding: 0;
	}
	@media (min-width: 1024px) {
		padding: 0 24px;
	}
	.Wrapper {
		margin-bottom: 80px;
		.contentWrapper {
			display: flex;
			@media (min-width: 1024px) {
				flex-wrap: nowrap !important;
			}
			@media (max-width: $md1440) {
				flex-direction: row;
				flex-wrap: wrap;
			}
			.formWrapper {
				max-width: 894px;
				width: 100%;
				@media (max-width: 1023px) {
					max-width: 100%;
				}
			}
			.similarWrapper {
				width: 100%;
				margin: 16px;
				@media (min-width: $sm768) {
					margin: 16px 0;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
				}
				@media (min-width: 1024px) {
					max-width: 282px;
					flex-direction: column;
					//justify-content: unset;
					justify-content: flex-start;
					padding-top: 0;
					margin-left: 12px;
				}
				@media (min-width: 1300px) {
					max-width: 282px;
					flex-direction: column;
					justify-content: unset;
					margin-top: 0;
					margin-left: 24px;
				}

				.oneSimilar:nth-child(3) {
					@media (max-width: 1023px) {
						display: none;
					}
				}
				.oneSimilar {
				}
				.oneSimilar:nth-child(2) {
					@media (max-width: $md) {
						margin-right: 24px;
					}
					@media (max-width: $sm768) {
						margin-right: 0;
					}
				}

				.title {
					width: 100%;
					font-size: 28px;
					color: $white1;
					margin-bottom: 24px;
					@media (max-width: $md) {
						width: 100%;
					}
				}
				.moreButton {
					width: 100%;
					display: block;
					text-align: right;
					@media (max-width: $md) {
						width: 100%;
					}
				}
			}
		}
		.breadcrumbs {
			@media (max-width: 767px) {
				margin-left: 24px;
			}
			color: rgba($white1, 0.7) !important;
			padding: 16px 0;
			.link {
				cursor: pointer;
				color: rgba($white1, 0.7);
				text-decoration: underline !important;
				&:hover {
					text-decoration: none !important;
				}
			}
			.currentLink {
				cursor: initial;
				text-decoration: none !important;
			}
		}
	}
}
