@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.addLink {
	text-decoration: underline;
	color: $gold;
	cursor: pointer;
	text-align: right;
	margin-right: 50%;
	margin-top: 16px;
	display: block !important;
}

.bottomSection {
	text-align: right;
}

.btnsWrap{
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
	@media (max-width: $sm) {
		justify-content: space-between;
	}

	.btn{
		width: 160px;
		height: 40px;
		&:first-child{
			margin-right: 16px;
		}
		@media (max-width: $sm) {
			width: calc(50% - 4px);
			margin-right: 0;
		}
	}
}

.addLink{
	display: block;
	width: 463px;
	@media (max-width: $md) {
		width: calc(100% - 90px + 38px);
	}
}

.itemsWrap{
	margin-top: 16px;
}
