@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";
.wrapper {
  display: inline-block;
  align-items: center;
  background: $white1;
  border-radius: 16px;
  padding: 4px 9px 4px 16px;
  margin-right: 8px;
  margin-top: 8px;

  .title {
    color: $text;
    line-height: 24px;
    padding-right: 10px;
    @media (max-width: $md) {
      font-size: 14px;
    }
  }
  .deleteIcon {
    vertical-align: middle;
    cursor: pointer;
  }
}
