@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.profileWrap {
  padding-top: 16px;
  padding-bottom: 80px;
  position: relative;
  .breadcrumbs {
    margin: 0 0 16px;
  }

  nav {
    color: $text2;

    @media (max-width: $md) {
      margin: 0 16px 16px 16px;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: $xl) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: $lg) {
    justify-content: center;
    padding-top: 64px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: $md) {
    padding-top: 16px;
  }

  .profileLeftColumn {
    width: 282px;
    margin-right: 24px;

    @media (max-width: $lg) {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (max-width: $md) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .profileInfo {
      @media (max-width: $md) {
        width: 282px;
        margin: auto;
        padding: 40px 16px 16px 16px;
        background: $black4;
      }
    }
  }

  .profileRightColumn {
    width: calc(100% - 306px);
    @media (max-width: $lg) {
      width: 100%;
    }
  }

  .videosWrap {
    margin-top: 24px;
    @media (max-width: $lg) {
      margin-bottom: 40px;
      margin-top: 96px;
    }
    @media (max-width: $md) {
      margin-top: 40px;
    }
  }
  .galleryWrap {
    margin-bottom: 24px;
    @media (max-width: $lg) {
      display: none;
      margin-top: 24px;
      margin-bottom: 0;
    }
    &.tablet {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }
    }
  }
  .alert {
    position: absolute;
    right: 0;
    top: 8px
  }
}
