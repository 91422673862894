@import "../../UIKit/colorVars";

.container{
  width: 100%;
  padding:0 16px 16px 16px ;
}

.cardsWrap {
  width: 100%;
  background: #212121;
  font-size: 16px;
  color:#FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 48px;
    font-size: 16px;
    padding: 0 16px 0 24px;
    cursor: pointer;
  }
  .arrowWrap{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrowClose {
    transform: rotate(0deg);
  }

  .arrowOpen{
    transform: rotate(180deg);
  }
}
