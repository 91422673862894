.note14m {
	font-weight: 500;
	font-size: 14px;
	line-height: 1.43;
	.marriWeather {
		font-family: "Merriweather", sans-serif;
		font-weight: normal;
	}
}
.note16r,
.note14r,
.note12r,
.note11r {
	font-weight: normal;
	.marriWeather {
		font-family: "Merriweather", sans-serif;
	}
}
.note16r {
	font-size: 16px;
}
.note14r {
	font-size: 14px;
	line-height: 1.43;
}
.note12r {
	font-size: 12px;
	line-height: 1.67;
}
.note11r {
	font-size: 11px;
	line-height: 1.45;
}
