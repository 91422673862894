@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.chatWrapper {
  background: $black4;
  max-width: 1200px;
  max-height: 680px;
  height: 680px;
  // height: 100%;
  width: 100%;
  display: flex;

  @media (max-width: $sm768) {
    margin: 0 24px;
  }
  @media (max-width: $xs425) {
    margin: 0px;
  }

  .chatsList {
    width: 288px;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d5d7db;
      border-radius: 10px;
    }

    @media (max-width: $xs425) {
      width: 100%;
    }

    &.closed {
      @media (max-width: $xs425) {
        display: none;
      }
    }
  }

  .chatRoom {
    height: 100%;
    // width: 100%;
    width: calc(100% - 288px);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (max-width: $xs425) {
      width: 100%;
    }
    &.closed {
      @media (max-width: $xs425) {
        display: none;
      }
    }

    .messagesContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 300;
      padding: 0 8px 8px 8px;

      &.withMessages {
        // flex-direction: column;
        // justify-content: flex-end;
        // overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d5d7db;
          border-radius: 10px;
        }
      }

      .viewPort {
        height: fit-content;
        width: 100%;
        margin-top: auto;

        &.withoutMessages {
          margin: 0;
        }

        .noMessages {
          text-align: center;
        }
      }

      .messagesList {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;

        .messagesDate {
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
          color: $text2;
        }

        .messageWrapper {
          display: flex;
          margin-bottom: 8px;

          @media (max-width: $xs425) {
            margin-bottom: 17px;
          }

          &.myMessage {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .loadingOldMessage {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.noChatsFoundMessage {
  color: #fff;
  padding: 16px;
  font-size: 16px;
}
