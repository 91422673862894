@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.wrapper{
  padding-top: 60px;
  padding-bottom: 24px;
  .container {
    width: 100%;
    padding: 0 16px;
    position: relative;
    @media (min-width: $md1440) {
      padding: 0;
    }
    .contentWrapper {
      display: flex;
      justify-content: space-between;

      .cardsWrapper{
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        height: fit-content;
        .showAllButton{
          text-align: center;
          width: 100%;
        }
        .communityCard {
          width: 100%;
          max-width: 100%;
        }
        .instituteCard {
          width: 100%;
        }
        .instituteCard:not(:last-child){
          margin-bottom: 24px;
        }
        .communityCard:not(:last-child){
          margin-bottom: 24px;
        }
        .card {
          width: 100%;
          margin-bottom: 24px;
          @media (min-width: $sm768) {
            width: calc(50% - 12px);
          }
        }

        .card:last-child{
          margin-bottom: 0;
        }
      }
      .trending{
        display: none;

        @media (min-width: $md1440) {
          display: block;
          margin-left: 24px;
        }
        text-align: right;
        width: 282px;
        min-width: 282px;
        max-width: 282px;
        .card:not(:last-child) {
          margin-bottom: 24px;
        }
        .showAll {
          text-transform: uppercase;
        }
      }
    }
  }
}

