@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.videosWrap {
  .topInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .addBtn {
      color: $gold;
      font-size: 14px;
      font-weight: 100;
      cursor: pointer;
    }
    .mainTitle {
      color: $white1;
      line-height: 1;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      a {
        color: white;
        text-decoration: none;
      }
      @media (max-width: $lg) {
        font-size: 16px;
      }
    }
  }
  .videoCard {
    margin-bottom: 8px;
    @media (max-width: $lg) {
      flex: 1 0 282px;
      margin-right: 16px;
    }
    @media (max-width: $md) {
      flex: 1 0 240px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .emptyVideoCard {
    @media (max-width: $lg) {
      // flex: 1 0 282px;
      width: 100%;
    }
    // @media (max-width: $md) {
    //   flex: 1 0 240px;
    // }
    & > div {
      @media (max-width: $lg) {
        max-width: 100%;
      }
    }
  }
  .videosWrapper {
    @media (max-width: $lg) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
}

.videosWrapper::-webkit-scrollbar-track {
  background-color: transparent;
  height: 1px;
}

.videosWrapper::-webkit-scrollbar {
  background: transparent;
  border-radius: 4px;
  height: 1px;
  background-clip: content-box;
}

.videosWrapper::-webkit-scrollbar-thumb {
  background: transparent;
  background-clip: content-box;
}
