@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.videoGalleryContainer {
  height: 100%;
  @media (max-width: $md) {
    width: 100% !important;
  }
}

.galleryContainerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 16px;
  min-height: 100%;
  height: auto;

  .galleryWrapper {
    background: #212121;
    width: 100%;
    height: 100%;
    margin-top: 16px;
    margin-bottom: 80px;

    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .topLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid #fff;
      height: 67px;
      color: #fff;
      @media (max-width: $sm) {
        padding: 0 16px;
      }
    }

    .videosWrapper {
      display: flex;
      padding: 32px 12px;
      flex-wrap: wrap;

      @media (max-width: $sm) {
        padding: 32px 16px;
      }

      .cardWrapper {
        height: auto;
        margin: 12px;
        width: calc((100% - (24px * 4)) / 4);
        max-width: 100%;

        @media (max-width: 1100px) {
          width: calc((100% - (24px * 3)) / 3);
        }

        @media (max-width: $sm) {
          width: calc((100% - (24px * 2)) / 2);
        }

        @media (max-width: 415px) {
          width: 100%;
        }
      }
    }
  }

  .galleryEmpty {
    // display: flex;
    color: white;
    font-weight: 100;
    position: relative;
    flex: auto;
    margin-bottom: 80px;

    .videosWrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-items: center;
      flex: 1;
      color: rgba($white1, 0.7);
    }
  }
  .breadcrumbs {
    color: rgba($white1, 0.7);
    @media (max-width: $md) {
      margin-left: 24px;
    }
    @media (max-width: $sm) {
      margin-left: 16px;
    }
    p {
      color: inherit;
    }
    a {
      text-decoration: underline;
    }
  }
  .addVideosBtn {
    width: 110px;
    height: 40px;
  }
}
