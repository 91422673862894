@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.container {
  margin-bottom: 40px;

  @media (max-width: $xs425) {
    margin: 0;
  }
  .wrapper {
    padding: 78px 0 40px;
    @media (min-width: $md1440) {
      padding-right: 0;
      padding-left: 0;
    }
    .cardsWrapper {

      @media (min-width: $sm768) {
        display: flex;
        justify-content: space-between;
      }


      .cardWrapper {
        cursor: pointer;
        @media (min-width: $sm768) {
          width: calc(50% - 12px);
        }

        @media (max-width: $xs425) {
          &:nth-child(2){
            display: none;
          }
        }
      }

      //.cardWrapper:nth-child(2) {
      //  display: none;
      //  @media (min-width: $sm768) {
      //    display: block;
      //  }
      //}
    }

    .text {
      font-weight: bold;
      font-family: Merriweather;
      font-size: 28px;
      line-height: 40px;
      color: #FFFFFF;
      margin-top: 16px;
    }

    .image {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

