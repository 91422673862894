// @import "./UIKit/mainTypography";
// @import "./UIKit/titleTypography";
// @import "./UIKit/noteTypography";
// @import "./UIKit/adaptiveVars";
// @import "./UIKit/colorVars";
// @import "./UIKit/cards";
@import "~react-toastify/dist/ReactToastify.css";
@import "~video-react/styles/scss/video-react";

@import "./UIKit/index";

.custom-toast-component {
  .Toastify__toast {
    border-radius: 4px;
  }

  .Toastify__toast--info {
    background-color: #2d98f0;
  }
}
