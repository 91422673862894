@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/mixins";
.copied {
  @include copied
}
.article {
  margin: 16px 24px;
  color: $text2;
  &Title, &Text {
    word-break: break-word;
    color: $text2;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    > div > p {
      min-height: 27px;
    }
  }
  &Title{
    margin-bottom: 16px;
  }
  &List {
    color: $text2;
    margin-left: 20px;
    list-style: disc;
  }
}
.article:not(:last-child){
  margin-bottom: 24px;
}
