@import "../../UIKit/colorVars";

.userImageContainer {
  position: relative;
  max-width: 144px;
  max-height: 144px;
  margin: auto;
  
  .imageContainer{
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
  }


  .crossIcon {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s;
    z-index:1;
  }

  .uploadImage{
    position: absolute;
    height: 40px;
    bottom: -40px;
    background: rgba(158, 158, 158, .75);
    width: 100%;
    text-align: center;
    opacity: .75;
    cursor: pointer;
    transition: all .3s;

    svg{
        margin-top: 9px;
    }
  }

  &:hover{
      .uploadImage{
          bottom: 0;
      }
      .crossIcon{
          opacity: 1;
      }
  }
}
