@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.userInfoContainer {
  background-color: #212121;
  padding: 24px 16px 16px 16px;
  width: 100%;
  max-width: 348px;
  position: relative;
  .reportIcon {
    position: absolute;
    right: 21px;
    top: 13px;
  }
  @media (max-width: $lg) {
    margin: 0 auto;
  }

  @media (max-width: $xs425) {
    max-width: 100%;
  }

  .imageContainer {
    margin-bottom: 32px;
  }
  .nameSection {
    position: relative;
    width: 100%;
    .editIconWrap {
      // margin-left: 20px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      svg {
        margin-left: 4px;
      }

      @media (max-width: $md) {
        margin-left: 14px;
      }
    }
  }

  .levelTypeContainer {
    background-color: #F5C02A;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin: -24px -16px 24px -16px;
  }
  .levelType {
    margin: 0;
  }
  .userNameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    &Employ {
      flex-direction: column;
    }
    .userName {
      color: $white1;
      white-space: pre-wrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      &Employ {
        margin-top: 12px;
        color: $gold;
        text-align: center;
        width: 100%;
      }
      &Full {
        padding: 0 24px;
      }
      &WithoutIcon {
        margin-left: 0;
      }
    }
  }
  .userEducation {
    margin-top: 8px;
    .title {
      text-align: center;
      color: $text2;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .userDescription {
    text-align: center;
    word-break: break-word;

    .title {
      color: $white1;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      margin-top: 24px;
    }
    .subTitle {
      padding: 0 16px;
      color: $text2;
    }
  }
  .chatButton {
    margin-top: 16px;
  }
  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .editItem {
    margin-bottom: 16px;
  }
  .inputsWrapper {
  }
  .saveButtons {
    width: calc(50% - (16px / 2));
  }
  .textArea {
    margin: 10px 0px 10px 0px;
    min-height: 144px;
  }
}
