@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.addPhotoPopup {
	width: 100%;
	max-width: 636px;
	.btn {
		@media (max-width: 420px) {
			width: calc(50% - 8px);
		}
	}
}
.contentWrapper {
	padding: 24px 0 32px 0;

	p {
		font-size: 18px;
		color: $text;
		line-height: 1;
		text-align: center;
		margin-bottom: 24px;

		&.errorMessage {
			color: $red4;
		}
	}

	.cropWrapper {
		max-width: 100%;
		max-height: 100%;
	}

	.defaultImage {
		margin: auto;
		border-radius: 50%;
		height: 290px;
		width: 290px;
		@media (max-width: ($md - 1)) {
			height: auto;
			width: 50%;
			max-width: 200px;
		}
	}

	input {
		display: none;
	}
}
