.card {
  width: 100%;
  max-height: 400px;
  padding: 16px;
  text-align: left;
  font-family: Merriweather;
  background-color: #212121;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2),
  0 3px 16px rgba(0, 0, 0, 0.12),
  0 9px 12px rgba(0, 0, 0, 0.14);
  cursor: pointer;

  .title {
    max-height: 108px;
    overflow: hidden;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
  }

  .description {
    max-height: 75px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .img {
    margin-top: 16px;
    height: 164px;
    background-size: cover;
  }
}
