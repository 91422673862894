@import "../../../../UIKit/adaptiveVars";
@import "../../../../UIKit/colorVars";

.topOpportunities {
  background: url("../../../../images/bg/topOpportunitiesBg.jpg") no-repeat;
  background-size: cover;
  color: white;
  padding: 40px 0 52px 0;
  overflow-x: hidden;
  background-size: cover;
  overflow-y: hidden;

  .topLine {
    display: flex;
    position: relative;

    .line {
      height: 2px;
      background: white;
      margin-top: 18px;
      flex-grow: 2;
    }

    h3 {
      width: 1200px;
      flex-grow: 2.2;

      @media (max-width: $sm768) {
        padding-left: 24px;
      }

      @media (max-width: $xs425) {
        padding-left: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background: white;
        margin-left: 5px;
      }
    }
  }

  .opportunitiesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: $xs425) {
      justify-content: center;
    }

    .opportunitiesList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      max-width: calc(100% - 408px);

      @media (max-width: $md1024) {
        max-width: 100%;
      }

      @media (max-width: $xs425) {
        justify-content: center;
      }

      & > div.opportunity {
        margin-bottom: 24px;
        width: calc((100% - 24px) / 2);

        @media (max-width: $md1024) {
          max-width: calc((100% - 24px) / 2);
        }

        @media (max-width: $xs425) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .btnWrapper {
      width: 100% !important;
      margin: 0 !important;
      max-width: 100% !important;
      text-align: center;
    }
  }
}
