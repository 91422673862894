@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";
@import "../../../UIKit/boxShadows";

.menu {
  transition: all 0.25s ease;

  @media screen and (min-width: $xl + 1px) {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: top right;
    background-color: #fff;
    box-shadow: $material5, 0px 6px 10px rgba(0, 0, 0, 0.14);
  }

  @media screen and (max-width: $xl) {
    border-top: 1px solid rgba(#fff, 0.08);
    margin-top: 16px;
  }

  &Visible {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &Section {
    padding: 8px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(#000, 0.12);
    }

    @media screen and (max-width: $lg) {
      &:last-child {
        padding-bottom: 125px;
      }
    }

    @media screen and (max-width: $xl) {
      border-color: rgba(#fff, 0.08) !important;
      padding: 16px 0;
    }
  }

  &Item {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    min-width: 180px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.08);
    }

    &Icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $xl) {
        display: none;
      }

      svg,
      svg * {
        fill: #000;
        fill-opacity: 0.54;
      }
    }

    &Text {
      padding: 0 16px;
      font-size: 16px;
      color: $text;
      flex: 1;

      @media screen and (max-width: $xl) {
        color: #fff;
        padding: 8px;
      }
    }
  }
}
