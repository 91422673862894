$primary: #b4182d;
$gold: #f4c00d;

$black1: #141518;
$black2: #070e14;
$black3: #2c2d30;
$black4: #212121;

$gray1: #404145;
$gray2: #606266;
$gray3: #7e8085;
$gray4: #9fa1a6;
$gray5: #babdc2;
$gray6: #d5d7db;
$gray7: #e8eaed;
$gray8: #9e9e9e;
$gray9: #c4c4c4;
$gray10: #424242;
$gray11: #6a808a;

$white1: #ffffff;
$white2: #f8f9fa;
$white3: #f2f4f6;

$red1: #f2323f;
$red2: #fa3f48;
$red3: #fc5f64;
$red4: #ff0c3e;

$pink1: #fc9c9e;
$pink2: #fcc7c8;
$pink3: #fcdcdd;
$pink4: #fcf2f2;

$cyan1: #4db6ac;

$purple1: #311b92;

$text: rgba(0, 0, 0, 0.87);
$text2: rgba(255, 255, 255, 0.7);
$secondaryText: rgba(0, 0, 0, 0.54);

$mainGradient: linear-gradient(#484848, #303030);
