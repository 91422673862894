.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  padding: 0 10px;
  user-select: none;
  text-align: center;
  width: 112px;
  height: 24px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  word-break: break-word;
  color: white;
}
.job {
  background-color: #FA3F48;

}
.mentorship {
  background-color:  #FF9800;
}
.project {
  background-color:  #7986CB;
}
.funding {
  background-color:  #4DB6AC;
}
.sponsorship {
  background-color:  #004D40;
}
.advisoryBoardProtocol {
  background-color:  #1565C0;
}
.boardOfDirectorsOpportunity {
  background-color: #7E57C2;
}

.supplier {
  background-color: #BF5361;
}
