@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.wrapper {
  padding: 40px 34px 32px 34px;
  width: 588px;
  height: 280px;
  background-color: $black4;
  text-align: center;
  @media (max-width: $xs425) {
    padding: 70px 0 40px 0;
    width: calc(100% - 32px);
    margin: 0 auto;
    height:  auto;
  }
  .icon {
    margin: 0 auto 34px;
  }
  .errorText {
    font-size: 21px;
    line-height: 152%;
    font-weight: 500;
    font-family: Roboto;
    color: $white1;
  }
}
