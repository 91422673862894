@import "../../UIKit/colorVars";

.DraftEditor-root {

  word-break: break-word;
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  background-color: #424242;
  border-radius: 2px;
  color: #ffffff;
  z-index: 3;
  padding: 60px 16px 12px;
  font-size: 16px;




  &Error {
    border: 1px solid red;
  }
}
.public-DraftEditorPlaceholder-root{
  color: rgba(255, 255, 255, 0.5)
}
.public-DraftEditorPlaceholder-inner { position: absolute; pointer-events: none; }
