@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.profileWrap {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 80px;
  @media (max-width: $xl) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: $lg) {
    justify-content: center;
    padding-top: 64px;
    padding-left: 0;
    padding-right: 0;
  }

  .profileLeftColumn {
    width: 282px;
    margin-right: 24px;
    // margin-top: 40px;
    @media (max-width: $lg) {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (max-width: $md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .profileRightColumn {
    width: calc(100% - 306px);
    @media (max-width: $lg) {
      width: 100%;
    }
  }

  .videosWrap {
    margin-top: 42px;
    @media (max-width: $lg) {
      margin-bottom: 40px;
      margin-top: 96px;
    }
    @media (max-width: $md) {
      margin-top: 40px;
    }
  }
  .galleryWrap {
    margin-bottom: 36px;
    @media (max-width: $lg) {
      display: none;
      margin-top: 24px;
      margin-bottom: 0;
    }
    &.tablet {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }
    }
  }
}
