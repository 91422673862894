@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.approveRejectWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;

  @media (max-width: $md) {
    padding-top: 0;
  }

  .reject,
  .approve {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background: #dadada;
    }
  }

  .active {
    path {
      fill: $gold;
    }
  }

  .reject {
    margin-right: 10px;

    svg {
      transform: rotate(180deg);
    }
  }
}
