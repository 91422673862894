@import "../../UIKit/colorVars";
@import "../../UIKit/boxShadows";

.popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 101;
  transform: translateY(-50%);
  // max-height: -webkit-fill-available;
  max-height: 100vh;

  &_backdrop {
    width: 100%;
    display: flex;
    align-content: center;
    height: 100vh;
  }
}

.wrap {
  background: $white1;
  // padding: 20px 0 0;
  box-shadow: $material8, 0px 12px 17px rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  width: 100%;
}

.wrap::-webkit-scrollbar {
  display: none;
}

.wrap {
  -ms-overflow-style: none;
}

.inner {
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  // height: fit-content !important;
  margin: auto;
  // width: 100%;
  max-width: calc(100% - 48px);

  @media (max-width: 340px) {
    width: 100% !important;
  }

  .popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 24px 20px 24px;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .title {
      line-height: 1;
      font-size: 18px;
      color: $text;
    }

    .crossIconContainer {
      height: 24px;
      background: transparent;

      div {
        cursor: pointer;
      }
    }
  }

  .childrenWrapper {
    padding: 0 24px 0 24px;
    flex-wrap: wrap;
    height: 100%;

    &OverflowScroll {
      overflow-y: auto;
    }
  }
}

.subtitle {
  margin-bottom: 0;
  color: $secondaryText;
  text-align: center;
  font-size: 20px;
  white-space: break-spaces;
}

.text {
  font-size: 18px;
  line-height: 18px;
  margin-top: 16px;
  color: $secondaryText;
  text-align: center;
}

.buttons {
  padding: 12px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:nth-child(2n) {
      margin-left: 16px;
    }
  }
}

.dialogButtonsGroup {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
}

.smallPopup {
  width: 400px;

  .popupHeader {
    justify-content: flex-end;
  }

  .buttons {
    padding: 16px;

    button {
      width: 83px;
      height: 40px;
      &:first-child {
        margin-left: 16px;
      }
    }
  }

  .subtitle {
    padding: 32px;
    font-size: 24px;
    line-height: 32px;
    color: $black1;
    font-weight: 400;
    white-space: break-spaces;
  }

  .popupHeader {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
