@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.galleryWrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  //flex-wrap: wrap;

  @media (max-width: $sm) {
    > div:nth-child(4) {
      display: none;
      overflow: hidden;
    }
    > div:nth-child(5) {
      display: none;
    }
  }

  .userWrap {
    width: 384px;
    height: 600px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    background-position: inherit;
    background-size: 384px auto;
  }

  .title {
    color: #ffffff;
    font-size: 18px;
    padding: 0 16px 32px 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
  }
}
