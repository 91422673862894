@import "./adaptiveVars";
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;

	&.marriWeather {
		font-weight: bold;
	}
}

h1 {
	font-size: 56px;
	line-height: 1.5;
	font-weight: 700;
	@media (max-width: $lg) {
		font-size: 36px;
		line-height: 1.56;
	}
	@media (max-width: $sm) {
		font-size: 24px;
		line-height: 1.33;
	}
}

h2 {
	font-size: 36px;
	line-height: 1.56;
	font-weight: 400;
	@media (max-width: $lg) {
		font-size: 24px;
		line-height: 1.33;
	}
	@media (max-width: $sm) {
		font-size: 21px;
		line-height: 1.52;
	}
}

h3 {
	font-size: 28px;
	line-height: 1.43;
}

h4 {
	font-size: 24px;
	line-height: 1.33;
	@media (max-width: $lg) {
		font-size: 21px;
		line-height: 1.52;
	}
}

h5 {
	font-size: 21px;
	line-height: 1.52;
	@media (max-width: $lg) {
		font-size: 18px;
		line-height: 1.5;
	}
	@media (max-width: $sm) {
		font-size: 16px;
	}
}

h6 {
	font-size: 18px;
	line-height: 1.5;
}
