.formWrapper {
  margin: 32px -24px 0 -24px;

  .fieldsWrapper {
    margin: 0 24px 8px 24px;

    &>div:nth-child(-n+2) {
      padding-bottom: 16px;
    }
  }

  .btsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    &.oneButtonWrapper {
      button {
        margin: 0 24px 0 16px;
      }
    }

    &>button {
      width: 83px;
      height: 40px;

      &:nth-child(2) {
        margin: 0 24px 0 16px;
      }
    }
  }

  .radioGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 8px 24px 8px 24px;
  }

  .roleSelectionTitle {
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
    line-height: 1.43;
    padding-bottom: 6px;
  }

  .myCustomLabelClass {
    color: rgba(0, 0, 0, 0.87);
    padding-left: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
  }

  .myCustomTitleClass {
    color: rgba(0, 0, 0, 0.87);
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .chooseRole {
    padding: 5px 10px;
  }

  .adminCapReached {
    color: rgba(0, 0, 0, 0.50);
    padding-left: 10px;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    display: flex;
    text-align: right;
  }
}