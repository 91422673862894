@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.departmentWrapper {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 22px;
  color: #fff;
  background: #212121;

  @media (max-width: $md) {
    flex-wrap: wrap;
  }

  .name {
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: $md) {
      margin-bottom: 6px;
      order: 1;
    }
  }

  .employees {
    font-weight: 300;
    width: 150px;
    color: rgba(255, 255, 255, 0.7);

    @media (max-width: $md) {
      width: 120px;
      order: 3;
      display: flex;
      align-items: center;
    }
  }

  .openOpportunities {
    font-weight: 300;
    width: 170px;
    color: $gold;

    @media (max-width: $md) {
      order: 2;
      display: flex;
      align-items: center;
    }
  }

  .threeDots {
    width: 24px;
    display: flex;
    justify-content: center;
    @media (max-width: $md) {
      order: 4;
    }
  }
}
