@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.wrapper {
  background: linear-gradient(#484848, #303030);
  width: 100%;
  padding: 24px 0;

  .paggination {
    text-align: right;
  }
}
