@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.cardWrapper{
  max-width: 435px;
  width: calc(50% - 12px);
  max-height: 412px;
  background-color: $black4;
  position: relative;
  padding: 24px 16px;
  margin-bottom: 24px;
  @media (max-width: $sm768) {
    width: 100%;
  }
  @media (min-width: $sm768) {
    width: calc(50% - 12px);
  }
  box-shadow:
          0 5px 6px rgba(0, 0, 0, 0.2),
          0 3px 16px rgba(0, 0, 0, 0.12),
          0 9px 12px rgba(0, 0, 0, 0.14);
  .label {
    position: absolute;
    right: 16px;
    top: -12px;
  }
  .thumbnail{
    width: 100%;
    height: 244px;
    overflow: hidden;
    margin-bottom: 24px;
    @media (max-width: $md1440) {
      width: 100%;
    }
    &Image {
      width: 100%;
    }
  }
  .userSection {
    display: flex;
    align-items: center;
    .companyLogo {
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      max-height: 40px;
      margin-right: 16px;
      display: inline-block;
    }
    .name {
      color: $white1;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .subName {
      color: $text2;
      font-size: 12px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .buttonWrapper {
    margin-top: 16px;
    text-align: right;
    .button:not(:last-child){
      margin-right: 16px;
    }
    .button{
      width: 100px !important;
    }
  }
  .link{
    cursor: pointer;
    text-decoration: none;
  }
}
