.formWrapper {
  margin: 32px -24px 0 -24px;

  .fieldsWrapper {
    margin: 0 24px 38px 24px;

    & > div:nth-child(1) {
      padding-bottom: 16px;
    }
  }

  .btsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    &.oneButtonWrapper {
      button {
        margin: 0 24px 0 16px;
      }
    }

    & > button {
      width: 83px;
      height: 40px;
      &:nth-child(2) {
        margin: 0 24px 0 16px;
      }
    }
  }
  .textArea {
    min-height: 72px;
  }
}
