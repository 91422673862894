@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.oppotunitiesWrapper {
  margin-top: 36px;
  width: 100%;

  .cardsWrapper {
    display: flex;
    justify-content: space-between;

    & > div {
      &:first-child {
        margin: 0 24px 0 0;

        @media (max-width: $sm768) {
          margin: 0 0 24px;
        }
      }
    }

    @media (max-width: $sm768) {
      padding: 0 16px;
    }

    @media (max-width: $sm768) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .showMoreBtn {
    flex-basis: 100%;
    width: 100%;
    text-align: right;
    margin-top: 16px;

    @media (max-width: $md) {
      padding: 0 16px;
    }
  }
}
