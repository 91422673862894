@import "../../UIKit/index";

.container {
  width: 1200px;
  margin: 25px auto 80px auto;
  background: $black4;
  padding-bottom: 24px;

  @media (max-width: $sm768) {
    width: 720px;
  }

  @media (max-width: $xs425) {
    width: 100%;
  }

  .header {
    height: 68px;
    padding: 0 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.32);
    margin-bottom: 8px;
    h2 {
      font-size: 21px;
      color: white;
    }
  }

  .cardWrapper {
    padding: 0 8px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 24px;
  }
}
