@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.addNewBtn {
  display: flex;
  justify-content: flex-end;
  color: $gold;
  text-decoration: underline;
  height: 40px;
  align-items: center;
  margin: 16px 0 24px 0;

  span {
    cursor: pointer;
  }
}
.btnsWrapper {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    justify-content: space-between;
  }

  button {
    width: 160px;
    height: 40px;
    @media (max-width: 600px) {
      width: calc(50% - 4px);
    }
    &:first-child {
      margin-right: 16px;
    }
  }
}

.errorMessage {
  color: $red1;
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  margin-bottom: 16px;
}

.hintMessage {
  font-size: 16px;
  font-weight: 100;
  display: flex;
  justify-content: flex-end;
  width: 574px;
  margin-bottom: 16px;

  @media (max-width: $lg) {
    margin-top: 12px;
    justify-content: flex-end;
    width: 503px;
  }
  @media (max-width: $sm) {
    margin-top: 12px;
    justify-content: center;
    width: 100%;
  }
}
