@import "../../UIKit/adaptiveVars";

.mainWrap {
  display: flex;
  align-items: flex-end;
  width: 100%;

  &.myMessage {
    justify-content: flex-end;

    .myMessageBg {
      background: rgba(255, 255, 255, 0.24);
    }
  }
  .partnerMessageBg {
    background: rgba(255, 255, 255, 0.08);
  }
  .systemRedBg {
    background: #f2453d !important;
  }
  .systemGreenBg {
    background: #1a9a05 !important;
  }
  .systemTealBg {
    background: #009688 !important;
  }
  .systemBlockBg {
    background: #b4182d !important;
  }

  .userAvatar {
    width: 40px;
    height: 40px;
    display: none;
    margin-right: 16px;

    &.myMessage {
      order: 2;
      margin-left: 16px;
    }

    @media (max-width: $xs425) {
      display: block;
    }
  }

  .container {
    padding: 8px;

    @media (max-width: $xs425) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .messageWrap {
    width: 386px;
    color: #ffffff;
    border-radius: 4px;
    word-break: break-word;

    span,
    mark {
      white-space: break-spaces;
    }

    @media (max-width: $sm768) {
      max-width: 70%;
    }

    .timeWrap {
      display: flex;
      justify-content: space-between;

      &.lineHeight {
        line-height: 1;
      }

      @media (max-width: $xs425) {
        justify-content: flex-end;
      }
    }

    .name {
      font-size: 16px;
      font-weight: 500;

      @media (max-width: $xs425) {
        display: none;
      }
    }
    .hiddenName {
      visibility: hidden;
    }

    .time {
      font-size: 11px;
      color: rgba(255, 255, 255, 0.7);
    }
    .text {
      text-align: left;
      font-size: 14px;
    }
    .myMessageWrap {
      display: flex;
      justify-content: space-between;

      @media (max-width: $xs425) {
        flex-direction: column;
      }

      .statusWrap {
        display: flex;
        align-items: center;
        height: 16px;

        @media (max-width: $xs425) {
          justify-content: flex-end;
        }

        .time {
          font-size: 11px;
          color: rgba(255, 255, 255, 0.7);

          @media (max-width: $xs425) {
            order: 2;
            margin: 0 0 0 8px;
          }
        }
      }
      .text {
        text-align: left;
        font-size: 14px;
        max-width: 290px;
        white-space: pre-line;
      }
    }
  }

  .systemMessage {
    position: relative;
    .text {
      span {
        max-width: 324px;
        font-weight: 500;
      }
    }

    .timeWrap {
      position: absolute;
      right: 8px;
    }
  }
}
