@import "../../UIKit/adaptiveVars";
@import "../../UIKit/colorVars";

.departmentForm {
  margin: 0 -24px;

  .inlineWrap {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;

    @media (max-width: $sm) {
      flex-direction: column;
      align-items: center;
    }

    & > div {
      max-width: 212px;
      width: 100%;

      @media (max-width: $sm) {
        max-width: 308px;
        width: 308px;

        margin-top: 16px;
      }

      input {
        // width: 212px !important;
        @media (max-width: $sm) {
          width: 100% !important;
        }
      }
    }

    .errorMessage {
      width: 100%;
      text-align: center;
      color: $red1;
      font-size: 16px;
    }
  }

  .btnsWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px 12px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    button {
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
  }
}
