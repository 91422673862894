@import "../../../UIKit/adaptiveVars";
@import "../../../UIKit/colorVars";

.wrapper {

  padding: 16px 24px;
  background-color: $black4;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  word-break: break-word;
  .userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .photo {
      min-width: 60px;
      width: 60px;

      min-height: 60px;
      height: 60px;
    }
    .name {
      margin-left: 24px;
      font-size: 21px;
      color: $white1;
    }
  }
  .bodyContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .body {
      font-size: 16px;
      line-height: 150%;
      color: $text2;
      margin-bottom: 24px;
      min-height: 288px;
    }
    .skills {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 20px;
      color: $white1;
    }
  }

}
