@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.signUpContainer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;

  .wrap {
    background: rgba(#212121, 0.9);
    padding: 24px;
    @media (max-width: $lg) {
      padding-left: 15%;
      padding-right: 15%;
    }
    @media (max-width: $md) {
      padding: 24px;
    }
    @media (max-width: $sm) {
      padding: 24px 16px 32px 16px;
    }
  }

  .mainTitle {
    color: #fff;
  }

  .subtitleWrap {
    margin: 24px 0 26px 0;
    background: #070e14;
    border-radius: 4px;
    position: relative;
    padding: 8px 24px 24px;
    color: #fff;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background: #b4182d;
    }

    .subtitle {
      padding-bottom: 8px;
      display: block;
      @media (max-width: $lg) {
        font-size: 16px;
      }
    }

    .description {
      white-space: pre-wrap;
    }
  }

  .actionBtnsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    &.withBackBtn {
      justify-content: space-between;
    }
  }

  .backBtn {
    border-color: $white1;
    color: $white1;
  }
  .nextBtn,
  .backBtn {
    width: 142px;
    @media (max-width: $sm) {
      width: 130px;
    }
  }

  .signInLinkWrap,
  .mobileSignInLinkWrap {
    color: #fff;
    margin-right: 24px;
    @media (max-width: $sm) {
      width: 100%;
      text-align: center;
      margin-top: 24px;
      margin-right: 0;
    }
    .signInLink {
      font-weight: normal;
      margin-left: 24px;
      @media (max-width: $sm) {
        color: #f4c00d;
      }
    }
  }

  .signInLinkWrap {
    @media (max-width: $sm) {
      display: none;
    }
  }
  .mobileSignInLinkWrap {
    display: none;
    @media (max-width: $sm) {
      display: block;
    }
  }

  .nextWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: $sm) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .signUpCard {
    width: 100%;
  }
}
