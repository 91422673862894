@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

footer {
  padding: 64px 0 33px 0;
  background-color: #1a1a1a;
  color: rgba(255, 255, 255, 0.7);
  @media (max-width: $lg) {
    padding: 59px 0 32px 0;
  }
  @media (max-width: $md) {
    padding: 24px 0 32px 0;
  }

  .title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 32px;
    line-height: 0.87;
    //display: block;
    display: flex;
    justify-content: center;
    @media (max-width: $lg) {
      font-size: 24px;
      line-height: 1.33;
      font-weight: 400;
    }
    @media (max-width: $md) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .logosWrap,
  .bottomWrap {
    display: flex;
    align-items: center;
  }

  .logosWrap {
    justify-content: center;
    margin: 41px 0 15px 0;
    @media (max-width: $lg) {
      margin: 21px 0 46px 0;
    }
  }
  .logoLink {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.32);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .logoLink img {
    @media (max-width: $lg) {
      display: block;
      width: 88px;
      height: auto;
    }
    @media (max-width: $sm) {
      width: 56px;
    }
  }

  .bottomWrap {
    justify-content: center;
    @media (max-width: $lg) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .socialsWrap {
    display: none;
    //display: flex;
    @media (max-width: $lg) {
      margin-top: 46px;
    }

    .social {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        svg path {
          opacity: 1;
        }
      }
    }
  }

  .privacyPolicy {
    display: block;
    text-align: center;
    margin-top: 40px;
    color: rgba(255, 255, 255, 0.32);
  }
  .privaceLink {
    color: gold;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
}

.logoSvg {
  text-align: center;

  &> svg {
    max-width: 100%;
  }
}
