@import "../../UIKit/colorVars";

.textArea{
  margin: 0;
  width: 100%;
  border: 1px solid transparent;
  background-color: $gray10;
  border-radius: 2px;
  color: $white1;
  padding: 12px 16px;
  font-size: 16px;
  overflow-y: auto;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &Border {
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
  &Error {
    border-color: $red4 !important;
  }
}
.fieldName {
  color: $text2;
  &Light {
    color: rgba(0, 0, 0, 0.87);
  }
}
.horizontal {
  resize: horizontal;
}
.vertical {
  resize: vertical;
}


.infoSection {
  display: flex;
  justify-content: flex-end;

  &Error {
    justify-content: space-between;
  }
}

.errorMessageWrap {
	display: flex;
	margin-top: 4px;
	top: 100%;
}

p.errorMessage {
	color: $white1;
	font-size: 11px;
	line-height: 16px;
	margin-left: 4px;
	text-align: left;
}
p.errorMessageLight {
  color: black;
}
.lightField {
  background-color: transparent;
}
.textAreaBorderLight {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.textAreaLight {
  color: #141518;
  font-size: 16px;
  line-height: 24px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
