@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.page {
  &Wrapper {
    background: linear-gradient(#494949 0%, #272727 100%);
    padding: 12px 0;
    min-height: 100%;
  }
}

.resources {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
