@import "../../UIKit/colorVars";

.textLimit {
  color: rgba(255, 255, 255, 0.7);;
  font-size: 12px;
  line-height: 14px;
}
.errored {
  color: $red4;
}
