@import "../../../../UIKit/colorVars";
@import "../../../../UIKit/adaptiveVars";

.wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	font-size: 16px;
}

.hiddenWrapper {
	display: none;
}

.content {
	text-align: right;
	width: 588px;
	margin-top: 16px;
	@media (max-width: 1160px) {
		width: 534px;
	}
	@media (max-width: $md) {
		width: 100%;
	}
}
.btnsWrap{
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
	@media (max-width: $sm) {
		justify-content: space-between;
	}

	.btn{
		width: 160px;
		height: 40px;
		&:first-child{
			margin-right: 16px;
		}
		@media (max-width: $sm) {
			width: calc(50% - 4px);
			margin-right: 0;
		}
	}
}

.contentText {
	padding-top: 12px;
	padding-right: 16px;
	text-align: initial;
	@media (max-width: $md) {
		padding-right: 0;
	}
}

.textArea {
	min-height: 144px;
}
