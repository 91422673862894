@import "../../../UIKit/colorVars";
@import "../../../UIKit/adaptiveVars";

.wrapper {
  background-color: $black4;
  position: relative;
  padding: 16px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);

  @media (min-width: $sm768) {
    height: 244px;
    width: 720px;
  }
  .label {
    position: absolute;
    right: 16px;
    top: -12px;
  }
  .content {
    display: flex;
    flex-direction: column;
    @media (min-width: $sm768) {
      flex-direction: row;
    }
    .image {
      cursor: pointer;
      width: 100%;
      margin-bottom: 12px;
      height: 212px;
      @media (min-width: $sm768) {
        width: 350px;
        height: 212px;
      }
    }
    .articleContent {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: $sm768) {
        margin-left: 16px;
      }
      .contentText {
        margin-bottom: 12px;
        cursor: pointer;
      }
      .title {
        font-family: Merriweather;
        color: $white1;
        line-height: 32px;
        font-size: 21px;
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .body {
        font-family: Merriweather;
        font-size: 16px;
        line-height: 24px;
        color: $white1;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .companyInfo {
          display: flex;
          align-items: center;
          .photo {
            width: 28px;
            height: 28px;
            margin-right: 23px;
          }
          .infoWrap {
            font-weight: 500;
            .name {
              font-size: 16px;
              line-height: 24px;
              color: $white1;
            }
            .time {
              font-size: 12px;
              line-height: 20px;
              color: $text2;
            }
          }
        }
        .likeCounter {
          width: 106px;
        }
      }
    }
  }
}
