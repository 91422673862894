@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.signInForm {
	background-color: transparent1;
	color: $white1;
	padding: 84px 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: auto;
	height: 80%;
	text-align: center;

	@media (max-width: $md) {
		background-color: #212121;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.inlineWrap {
		width: 100%;
		@media (max-width: $md) {
			width: 70%;
			margin: 0 auto;
		}
		@media (max-width: $sm) {
			width: 100%;
		}
	}

	.title {
		margin-bottom: 24px;
	}

	.fieldWrap {
		margin-bottom: 16px;
	}

	.signInBtn {
		margin-top: calc(40px - 16px);
	}

	.forgotPassLink,
	.signUp {
		color: gold;
		display: block;
		margin-top: 24px;
	}

	.signUp {
		text-decoration: none;
		color: $white1;
		&Link {
			margin-left: 24px;
		}
	}
}
