@import "../../../UIKit/index";

.employeeWrapper {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 22px;
  color: #fff;
  background: #212121;

  @media (max-width: $md) {
    flex-wrap: wrap;
  }

  .name {
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;

    @media (max-width: $md) {
      margin-bottom: 6px;
    }

    @media (max-width: $xs425) {
      //width: fit-content;
      width: 25%;
      margin-bottom: 0;
      max-width: 100px;
      text-overflow: ellipsis;
    }

    @media (min-width: $xs425) {
      width: 116px;
    }
  }

  .employeeInfoContainerWithNameAndRole {
    display: flex;
    align-items: center;
    width: 33%;
    flex-wrap: wrap;
  }

  .role {
    margin-left: 10px;
    color: #f4c00d;
  }

  .employee_role {
    font-weight: 300;
    width: 150px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);

    @media (max-width: $md) {
      width: 120px;
      display: flex;
      align-items: center;
    }

    @media (max-width: $xs425) {
      width: 100%;
      order: 3;
      font-size: 12px;
    }
  }

  .email {
    font-weight: 300;
    width: 150px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);

    @media (max-width: $md) {
      width: 120px;
      display: flex;
      align-items: center;
    }

    @media (max-width: $xs425) {
      width: 100%;
      order: 3;
      font-size: 12px;
    }
  }

  .statusWrapper {
    display: flex;
    align-items: center;
    min-width: 91px;
    justify-content: space-between;

    @media (max-width: $xs425) {
      width: 75%;
    }

    .status {
      font-weight: 300;
      // width: 170px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;

      &.hidden {
        visibility: hidden;
      }

      @media (max-width: $md) {
        display: flex;
        align-items: center;
      }

      @media (max-width: $xs425) {
        font-size: 12px;

        &::before {
          content: "(";
          font-size: 12px;
        }

        &::after {
          content: ")";
          font-size: 12px;
        }
      }
    }

    .threeDots {
      width: 24px;
      display: flex;
      justify-content: center;
    }
  }
}