@import "../../../../../UIKit/colorVars";
@import "../../../../../UIKit/adaptiveVars";

$localBp: 1160px;
$localBp2: 1100px;

.educationWrapper,
.staticEducationWrapper {
  margin-top: 16px;

  .btns {
    display: flex;
    justify-content: flex-end;

    .addNewBtn {
      display: flex;
      justify-content: flex-end;
      color: $gold;
      height: 40px;
      align-items: center;
      margin: 16px 0 24px 0;

      &:nth-child(2) {
        margin-left: 16px;
      }

      span {
        cursor: pointer;
      }
    }

    .deleteBtn {
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .itemWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    @media (max-width: $md) {
      display: block;
      width: 100%;
    }

    & > p {
      color: $text2;
      font-size: 16px;
      // @media (max-width: $md) {
      // 	display: none;
      // }
    }

    .mobileLabel {
      display: none;
      @media (max-width: $md) {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: rgba($white1, 0.7);
        margin-bottom: 4px;
      }
    }

    .formWrapper {
      width: 588px;
      overflow-wrap: anywhere;
      word-wrap: anywhere;
      -webkit-hyphens: auto;

      @media (max-width: $localBp) {
        align-items: center;
        width: 534px;
      }
      @media (max-width: $localBp2) {
        width: 70%;
      }
      @media (max-width: $lg) {
        width: 534px;
      }
      @media (max-width: $md) {
        width: 100%;
      }

      .period {
        &:first-child {
          .dropdownMonth {
            &.tablet {
              display: none;
              @media (max-width: $localBp) {
                display: block;
              }
            }
          }
          .dropdownYear {
            @media (max-width: $localBp) {
              display: none;
            }
          }
        }

        &:last-child {
          .dropdownYear {
            &.tablet {
              display: none;
              @media (max-width: $localBp) {
                display: block;
              }
            }
          }
          .dropdownMonth {
            @media (max-width: $localBp) {
              display: none;
            }
          }
        }

        .dropdownMonth {
          width: 140px;
          @media (max-width: $localBp) {
            width: 100%;
            margin-bottom: 16px;
          }
          &:last-child {
            @media (max-width: $localBp) {
              margin-bottom: 0px;
            }
          }
        }
        .dropdownYear {
          margin-left: 16px;
          width: 120px;
          @media (max-width: $localBp) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 16px;
          }
          &:last-child {
            @media (max-width: $localBp) {
              margin-bottom: 0px;
            }
          }
          &.mobile {
            @media (max-width: $md) {
              margin-top: 24px;
            }
          }
        }
      }
    }
    .periodWrapper {
      display: flex;
      align-items: stretch;

      .devider {
        width: 44px;
        text-align: center;
      }
    }
  }
}

.educationWrapper {
  .formWrapper {
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    -webkit-hyphens: auto;

    @media (max-width: $localBp) {
      justify-content: space-between;
    }
  }
  .devider {
    line-height: 45px;
    @media (max-width: $localBp) {
      display: none;
    }
  }
  .period {
    display: flex;

    @media (max-width: $localBp) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: calc(50% - 10px);
    }
  }
}

.staticEducationWrapper {
  margin-top: 24px;

  .formWrapper {
    overflow-wrap: anywhere;
    word-wrap: anywhere;
    -webkit-hyphens: auto;

    p {
      color: #fff;
      height: fit-content;
    }
  }
  .devider {
    line-height: 24px;
    display: block;
  }
  .period {
    display: flex;
    width: auto;
  }
}
