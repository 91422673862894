@import "../../UIKit/index";

.containerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .box {
    width: 588px;
    // min-height: 377px;
    background: #212121;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    color: white;

    h2 {
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      padding: 16px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      margin-bottom: 16px;

      @media screen and (max-width: $sm768) {
        border: none;
      }
    }

    p.text {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 16px;
    }

    @media screen and (max-width: $sm768) {
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.successMsgText {
  text-align: center;
  padding: 0 16px;
}
