@import "../../UIKit/adaptiveVars";
@import "../../UIKit/colorVars";

.prospectsWrapper {
  padding-top: 31px;

  @media (max-width: $md1440) {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: $md1440) {
    padding-top: 31px;
    display: block;
  }

  .filterWrap {
    width: 282px;
    display: inline-block;
    margin-left: 23px;

    @media (max-width: $md1440) {
      order: -1;
      max-width: unset;
      width: 100%;
      padding: 16px;
      margin-left: 0;
    }

    @media (min-width: $sm768) {
      padding: 24px;
    }

    @media (min-width: $md1440) {
      order: unset;
      display: inline-block;
      width: 282px;
      margin-left: 0;
      padding: 0;
    }
  }

  .leftColumn {
    float: left;
    max-width: 895px;
    width: 100%;
    min-height: 1200px;

    @media (max-width: $md1440) {
      max-width: unset;
      padding: 0 24px 24px;
    }

    @media (max-width: $sm768) {
      max-width: unset;
      padding: 0 16px 16px;
      min-height: 100%;
    }

    @media (min-width: $sm768) {
      padding: 0 24px 24px;
    }

    @media (min-width: $md1440) {
      float: left;
      max-width: 895px;
      width: 100%;
      min-height: 1200px;
    }
  }

  .trendingWrap {
    display: inline-block;
    width: 282px;
    margin-left: 23px;

    @media (max-width: $md1440) {
      padding: 24px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: 0;
    }

    @media (max-width: $sm768) {
      padding: 16px;
    }

    @media (min-width: $md1440) {
      display: inline-block;
      width: 282px;
      margin-left: 0;
      padding: 0;
    }

    .trendingTitle {
      width: 100%;
      font-size: 28px;
      color: $white1;
      margin: 24px 0;
    }

    .trendingCard {
      margin-bottom: 24px;

      @media (max-width: $sm768) {
        width: 100%;
      }
      @media (min-width: $sm768) and (max-width: $md1440) {
        width: calc(50% - 12px);
      }
      @media (min-width: $md1440) {
        display: block;
        width: 100%;
      }

      .trendingTitle {
        width: 100%;
        font-size: 28px;
        color: $white1;
        margin: 24px 0;
      }

      .trendingCard {
        margin-bottom: 24px;

        @media (min-width: $sm768) and (max-width: $md1440) {
          width: calc(50% - 12px);
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        @media (min-width: $md1440) {
          width: 100%;
        }
      }
    }

    .prospects {
      margin-bottom: 32px;
    }
  }
  .filterMobile {
    display: none;
    @media (max-width: $md1440) {
      display: flex;
    }
    @media (min-width: $md1440) {
      display: none;
    }
  }
  .filterDesktop {
    display: none;
    @media (min-width: $md1440) {
      display: flex;
    }
  }
  .tagsWrap {
    padding: 24px 0;
  }
}
