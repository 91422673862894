@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.cardWrap {
  background-color: #212121;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: left;
  margin-top: 16px;
  .title {
    color: #ffffff;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
  }

  .showAllBtn {
    color: $gold;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: right;
    display: block;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 100;
    cursor: pointer;
  }

  .infoWrap {
    color: #ffffff;
    font-size: 14px;
    .dateof {
      color: rgba(255, 255, 255, 0.7);
      padding-top: 16px;
    }
    .prospectInfo {
      .type {
        float: left;
        padding-right: 2px;

        &::first-letter {
          text-transform: capitalize;
        }
      }
      .job {
        color: #fa3f48;
      }
      .mentorship {
        color: #ff9800;
      }
      .project {
        color: #7986cb;
      }
      .funding {
        color: #4db6ac;
      }
      .sponsorship {
        color: #004d40;
      }
      .advisoryBoardProtocol {
        color: #1565c0;
      }
      .boardOfDirectorsOpportunity {
        color: #7e57c2;
      }
      .supplier {
        color: #bf5361;
      }
      @media (max-width: $md) {
        float: left;
        height: 30px;
        display: flex;
        align-items: center;
      }
      @media (max-width: $sm) {
        float: none;
        height: 100%;
        display: block;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      display: block;

      .rank {
        cursor: pointer;
        display: block;
      }
    }
  }
}
