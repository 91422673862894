@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.homeWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topSection {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // height: 100%;
    min-height: calc(100vh - 56px);
    display: flex;
    align-items: center;

    .inlineWrap {
      padding-bottom: 48px;
    }

    .title {
      color: $white1;
      text-align: center;
      margin-bottom: 16px;
      text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2),
        0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
      @media (max-width: $lg) {
        margin-bottom: 24px;
      }
      @media (max-width: $sm) {
        margin-bottom: 40px;
      }
    }
  }
  .communityWrapper {
    background: linear-gradient(180deg, #494949 0%, #272727 100%);
  }
}
