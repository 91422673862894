@import "../../UIKit/colorVars";

.wrapper{
  position: relative;
  .fieldName {
    color: rgba(255, 255, 255, 0.7);
  }
  .toolbar {
    position: relative;
    top: 0;
  }
  p.errorMessage {
    color: $white1;
    font-size: 11px;
    line-height: 16px;
    margin-left: 4px;
    text-align: left;
  }

  .infoSection {
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;

    &Error {
      justify-content: space-between;
    }
  }
  .editorWrapper {
    border: 1px solid rgba(255, 255, 255, 0.7);
    &Error {
      border-radius: 2px;
      border: 1px solid red;
    }
  }
  .errorMessageWrap {
    display: flex;
    margin-top: 4px;
    top: 100%;
  }
}
