@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.userInfoContainer {
  background-color: $black4;
  padding: 40px 12px 24px;
  position: relative;
  max-width: 348px;
  margin: 0 auto;
  .reportIcon {
    position: absolute;
    right: 21px;
    top: 13px;
  }
  .imageContainer {
    margin-bottom: 32px;
  }

  .userNameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 24px;

    .editIconWrap {
      // margin-left: 20px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      svg {
        margin-left: 4px;
      }

      @media (max-width: $md) {
        margin-left: 14px;
      }
    }

    .userName {
      color: $gold;
      // margin: 0 20px 0 36px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      &WithoutIcon {
        margin: 0;
      }
    }
  }
  .userTitle {
    margin-top: 8px;
    .title {
      text-align: center;
      color: $text2;
    }
  }
  .userEducation {
    margin-top: 8px;
    .title {
      text-align: center;
      color: $text2;
    }
  }

  .userProfession {
    margin-top: 8px;
    .title {
      text-align: center;
      color: $text2;
    }
  }

  .userGender {
    margin-top: 8px;
    .title {
      text-align: center;
      color: $text2;
    }
  }

  .chatButton {
    margin-top: 16px;
  }
  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .editItem {
    margin-bottom: 16px;
    color: $text2 !important;
  }
  .userInfoInputs {
    @media (max-width: 768px) {
      max-width: 534px;
      margin: 0 auto;
      width: 100%;
    }
    @media (max-width: $lg) {
      max-width: 534px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .saveButtons {
    width: calc(50% - (16px / 2));
  }
}
