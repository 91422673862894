@import "../../../../UIKit/colorVars";
.title {
  color: $text;
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
}

.message {
  color: $gray2;
  text-align: center;
  margin-bottom: 28px;
  font-size: 14px;
}

.btnsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around; // ie11 doesn't support space-evenly
  margin-bottom: 32px;
}
