@import "../../UIKit/index";

.formWrapper {
  @media screen and (max-width: $sm768) {
    width: 530px;
  }

  @media screen and (max-width: $xs425) {
    width: 100%;
    padding: 0 16px;
  }

  .fieldsWrapper {
    padding: 0 24px 24px 24px;

    @media screen and (max-width: $sm768) {
      padding: 0 0 40px 0;
    }

    & > div:nth-child(1) {
      margin-bottom: 16px;
    }
  }

  .btnsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    height: 67px;

    @media screen and (max-width: $sm768) {
      border: none;
      justify-content: space-between;
    }

    & > button {
      width: 262px;
      height: 48px;

      @media screen and (max-width: $sm768) {
        width: 257px;
      }

      @media screen and (max-width: $xs425) {
        width: 157px;
      }
    }
  }
}
