@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.subscriptionBox {
  margin-top: 24px !important;
  position: relative;
  background: #212121;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
  .trialText {
    padding: 14px;
    text-align: center;
    font-weight: 500;
    line-height: 32px;
    color: #fff;
    span {
      display: inline-block;
      margin-left: 16px;
      padding-left: 16px;
      padding-right: 16px;
      height: 32px;
      font-size: 16px;
      border: 1px solid #fff;
      border-radius: 2px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .subscriptionMsg {
    padding: 24px;
    text-align: left;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
  }
}

.profileWrap {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 80px;

  @media (max-width: $xl) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: $lg) {
    justify-content: center;
    padding-top: 64px;
    padding-left: 0;
    padding-right: 0;
  }

  .profileLeftColumn {
    width: 282px;

    @media (max-width: $lg) {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
    }

    @media (max-width: $md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .profileRightColumn {
    width: calc(100% - 306px);

    @media (max-width: $lg) {
      width: 100%;
      padding: 0 16px;
    }

    @media (max-width: $md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .videosWrap {
    margin-top: 24px;

    @media (max-width: $lg) {
      margin-bottom: 40px;
      margin-top: 96px;
    }

    @media (max-width: $md) {
      margin-top: 40px;
    }
  }

  .galleryWrap {
    margin-bottom: 24px;

    @media (max-width: $lg) {
      display: none;
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  .tablet {
    display: none;

    @media (max-width: $lg) {
      display: block;
    }
  }

  .departmentsContainer {
    margin-top: 32px;

    @media (max-width: $xl) {
      margin-left: 0;
    }
  }
}

.resources {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -12px 0;

  @media screen and (max-width: $sm768) {
    margin: 24px 6px 0;
  }
}

.showMoreBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
}
