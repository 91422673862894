.Cal__Weekdays__root {
  background: #b4182d !important;
  font-family: "Roboto", sans-serif;
}

.Cal__Day__month,
.Cal__Day__day {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  color: #b4182d !important;
}

.Cal__Today__chevron {
  margin-left: 65px !important;
}

.Cal__MonthList__root {
  overflow-x: hidden !important;
}
