@import "../../UIKit/colorVars";

.chatWrap {
  width: 100%;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 11px 24px 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #212121;

  & svg {
    cursor: pointer;
  }
}

.textareaWrap {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 16px;
  max-width: 829px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 16px;

  &Error {
    border: 1px solid red;
  }
}
.errorMessageWrap {
  display: flex;
  margin-top: 4px;
}

p.errorMessage {
  color: $white1;
  font-size: 11px;
  line-height: 16px;
  margin-left: 4px;
  text-align: left;
}

textarea {
  width: 100%;
  outline: none !important;
  border: unset;
  color: white;
  background: unset;
  resize: none;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 6px;
  overflow-x: hidden;
  font-size-adjust: 100%;
  font-family: monospace;
  display: flex;
}
textarea::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.24);
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.5);
}
.textarea {
  font-size: 16px;
  .textarea::-webkit-scrollbar {
    width: 0;
  }
}
