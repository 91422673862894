@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";
.opportunitiesContainerWrapper {
  background: linear-gradient(180deg, #494949 0%, #272727 100%);
  min-width: 100%;
  min-height: 100%;
  .opportunitiesContainer {
    margin: 0 auto;
    padding-top: 16px;
    min-height: 100%;

    .navBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 36px;

      @media (max-width: $xs425) {
        // flex-direction: column;
        // justify-content: center;
      }

      &Title {
        color: $white1;
      }

      &ButtonHandler {
        @media (max-width: $sm768) {
          width: auto;
        }
      }

      &Button {
        min-width: 182px;

        @media (max-width: $sm768) {
          min-width: 80px;
        }
      }
      &ButtonDisabled {
        display: none;
      }
    }
    .opportunitiesWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div {
        max-width: unset;
        margin: initial;
        min-width: calc(50% - 12px);
        margin-bottom: 24px;
        width: calc(50% - 24px);
        @media (max-width: $sm) {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }
      }
      > div:nth-child(2n-1) {
        margin-right: 24px;
        @media (max-width: $sm) {
          margin-right: 0;
        }
      }

      @media (max-width: $sm) {
        flex-direction: column;
      }
    }
    @media (max-width: $xl) {
      padding: 16px;
    }

    @media (max-width: $md) {
      width: 720px;
      padding: 16px 0 0;
    }

    @media (max-width: $sm) {
      width: 100%;
      padding: 16px;
    }

    .breadcrumbs {
      margin-bottom: 16px;
      color: rgba($white1, 0.7);

      @media (max-width: $sm) {
      }

      .link {
        color: rgba($white1, 0.7);
        text-decoration: underline !important;
        &:hover {
          text-decoration: none !important;
        }
      }
      .currentLink {
        cursor: initial;
        text-decoration: none !important;
      }
    }

    .departmentsBox {
      background: $black4;
      min-height: 606px;

      .topInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 14px 22px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.32);
      }

      .emptyPage {
        height: 85%;
        font-weight: 300;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .tooltipWrapper {
    cursor: pointer;
    @media (max-width: $xs425) {
      width: 100%;
    }

    & > button {
      @media (max-width: $xs425) {
        width: 100%;
      }
    }
  }
}
