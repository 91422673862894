@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.searchForm {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 24px;
  text-align: center;

  @media (max-width: $sm) {
    padding: 24px 16px;
  }

  .title {
    color: $black2;
    padding: 0 30px 16px;
    @media (max-width: $lg) {
      padding: 0 0 16px;
    }
  }

  form {
    .field {
      margin-right: 24px;
      height: 48px;
      @media (max-width: $sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
      input {
        -webkit-appearance: none;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
    .dropdownWrap {
      width: 180px;
      margin-right: 24px;
      @media (max-width: $lg) {
        width: 162px;
      }
      @media (max-width: $sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
    .btn {
      width: 180px;
      height: 48px;
      @media (max-width: $lg) {
        width: 162px;
      }
      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }
}
