.primary,
.darkRounded,
.rounded {
  cursor: pointer;
}

.darkRounded {
  color: rgb(191,192,192);
  &:hover {
    border-radius: 50%;
    background-color: #dadada;
    color: white;
  }
  &:active {
    background-color: #dadada;
    border-radius: 50%;
    color: rgb(56,57,60);
  }
}

.primary{
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: rgb(78,79,81);
  }
  &:active {
    color: rgb(56,57,60);
  }
}

.rounded {
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: rgb(78,79,81);
    border-radius: 50%;
  }
  &:active {
    color: rgb(56,57,60);
    border-radius: 50%;
  }
}


