@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

$border-bottom: rgba(255, 255, 255, 0.12);

.departmentItem {
  margin-top: 32px;
  border-bottom: 1px solid $border-bottom;
  // &:first-child {
  //   margin-top: 0;
  // }
  &.withData {
    border-bottom: none;
  }
  .departmentHead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $md) {
      padding: 0 16px;
    }

    .departmentHeadTitle {
      color: $white1;
    }
    .departmentHeadBtn {
      color: $white1;
      text-decoration: none;
      border-radius: 2px;
      flex-shrink: 0;
    }
  }
  .departmentBody {
    margin-top: 8px;
    margin-bottom: 11px;
    .departmentBodyPlaceholder {
      padding: 8px 0;
      color: $text2;

      @media (max-width: $md) {
        padding: 0 16px;
      }
    }
  }
  .tooltipWrapper {
    cursor: pointer;
  }
}
