@import "../../UIKit/colorVars";
.gallery {
	height: 100%;
	padding-bottom: 80px;
	.breadcrumbs {
		color: rgba($white1, 0.7);
		padding: 16px 0;
		.link {
			color: rgba($white1, 0.7);
			text-decoration: underline !important;
			&:hover {
				text-decoration: none !important;
			}
		}
		.currentLink{
			cursor: initial;
			text-decoration: none !important;
		}
	}
	.photoGallery {
		min-height: calc(100% - 80px - 59px);
		height: auto;
	}

}
