@import "../../UIKit/adaptiveVars";
.item {
	height: 322px;
	background: #070e14;
	border-radius: 4px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	padding: 16px;
	opacity: 0.2;
	@media (max-width: $lg) {
		padding: 48px 16px 24px 16px;
		height: auto;
	}

	&:hover,
	&.selected {
		opacity: 1;

		.title,
		.description {
			color: #fff;
		}
	}
	&.selected {
		opacity: 1;
		span {
			border-color: #b4182d;

			&::before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #b4182d;
				position: absolute;
				left: 2px;
				top: 2px;
			}
		}
	}

	span {
		position: absolute;
		top: 20px;
		right: 20px;
		border-radius: 50%;
		border: 2px solid #dadada;
		background-color: transparent;
		width: 18px;
		height: 18px;

		&::before {
			display: none;
		}
	}

	svg {
		display: block;
		margin: 0 auto;
		@media (max-width: $lg) {
			width: 86%;
		}
	}

	.title {
		margin: 38px 0 8px;
		color: rgba(255, 255, 255, 0.8);
		@media (max-width: $lg) {
			margin-top: 24px;
		}
	}

	.description {
		color: rgba(255, 255, 255, 0.7);
		@media (max-width: $lg) {
			font-size: 16px;
		}
	}
}
