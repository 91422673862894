@import "../../UIKit/colorVars";
@import "../../UIKit/adaptiveVars";

.profileTable {
  background-color: #212121;
  padding: 16px 24px;
  color: $white1;
  width: 100%;
  @media (max-width: $md) {
    padding: 16px;
  }

  .tableItem {
    margin-top: 32px;
    &:first-child {
      margin-top: 0;
    }
    .titleWrap {
      display: flex;
      white-space: nowrap;
      align-items: center;

      .title {
        @media (max-width: $lg) {
          font-size: 21px;
        }
      }
      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white1;
        margin-left: 8px;
        flex: 1;
      }
      svg {
        display: block;
        width: 16px;
        height: auto;
      }
    }

    .description {
      color: rgba(255, 255, 255, 0.7);
      margin-top: 8px;
      margin-bottom: 6px;
    }
    .link {
      font-size: 16px;
      line-height: 24px;
    }
    .editIconWrap {
      margin-left: 14px;
    }
  }
  .noInfo {
    color: $text2;
    margin: 8px 0;
    font-size: 16px;
    line-height: 24px;
  }
}

.profileTableFreelancer {
  @media (max-width: $lg) {
    margin-top: 10px;
  }
}
